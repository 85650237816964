import * as React from 'react';
import API from './apiClient';
export default class FoodsApi {
  static connectFoods(data) {
    return API.ax.post(`foods/`, data).catch((c) => {
      console.log(c);
    });
  }
  static getRestaurantFoods(rid) {
    return API.ax
      .post('foods/', {
        argument: 'getRestaurantFoods',
        id: rid,
        type: 'user',
        lon: 0,
        lat: 0
      })
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
