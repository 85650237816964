import {
  ButtonGroup,
  Grid,
  FormControlLabel,
  TextField,
  Radio,
  FormControl,
  Button,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  RadioGroup,
  Typography,
  Card,
  Chip,
  Stack
} from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import API from 'src/modules/apiClient';
import { useAPI } from 'src/modules/contenthandler';
import { LoadingButton, TimePicker } from '@mui/lab';
import { HFileReader } from '../hotpot';
import { Link } from 'react-router-dom';
import PageBuilder from '../PageBuilder';

export default function HotpotQueestionnaire(props) {
  const [questionnaire, setQn] = React.useState({
    initialValues: {},
    name: '',
    questions: [],
    topic: { name: '', nextscreen: '', id: '0' }
  });
  const { uid } = useParams();
  const { post, get, uploadFile } = useAPI();
  const [scheme, setScheme] = React.useState({});
  const [serr, setSerr] = React.useState('');
  const [file, setFiles] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  let navigate = useNavigate();

  const onChange = (e, name) => {
    let g = file;
    g[name] = e;
    setFiles(g);
    console.log(file);
  };
  React.useEffect(() => {
    // alert(uid);
    get('questionnaire/list/' + (!uid ? '3' : uid)).then((r) => {
      let valscme = r.data.initialValues;
      let i = 0;
      r.data.names.map((prop, key) => {
        // console.log(prop[2]);
        valscme[prop[0] + '_' + prop[1]] = Yup.string();
        i++;
      });
      setQn(r.data);
      setScheme(valscme);
      setFiles(r.data.files);
      setSerr('');
    });
  }, [uid]);
  const onSubmit = () => {
    const data = { data: values, myKeys: questionnaire.names };
    setSubmitting(true);
    let formm = new FormData();
    formm.append('data', JSON.stringify(values));
    formm.append('topic', questionnaire.topic.id)
    formm.append(
      'filenames',

      questionnaire.filenames.map((prop) => {
        return prop[0] + (prop[1] ? '[]' : '');
      })
    );
    questionnaire.filenames.map((prop) => {
      console.log(file);
      file[prop[0]].map((propp) => {
        formm.append(prop[0] + (prop[1] ? '[]' : ''), propp);
      });
    });
    formm.append(
      'myKeys',
      questionnaire.names.map((element) => {
        return element[0] + '_' + element[1];
      })
    );
    uploadFile('questionnaires/questions/save', formm).then((r) => {
      if (r.data.status) {
        navigate('/pageloader/' + questionnaire.topic.nextscreen, { data: r.data });
      } else {
        setSerr(r.data.message);
        window.location.href = '#widerr';
      }
      setSubmitting(false);
    });
  };
  const formik = useFormik({
    initialValues: questionnaire.initialValues,
    validationSchema: Yup.object().shape(scheme)
  });
  const { touched, errors, values, handleSubmit, getFieldProps } = formik;
  // const navigate = useNavigate();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {questionnaire.topic.name}
          </Typography>
          <Typography
            id="widerr"
            variant="body1"
            style={{ textAlign: 'center', fontWeight: 'bold', color: '#ff0000' }}
          >
            {serr}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormikProvider value={formik}>
            <Form encType="multipart/form-data" autoComplete="off" onSubmit={handleSubmit}>
              <Grid container columnSpacing={1}>
                {questionnaire.questions.map(({ question, options }, key) => (
                  <Grid
                    item
                    key={key}
                    xs={question.styles.xs}
                    lg={question.styles.lg}
                    md={question.styles.md}
                  >
                    <div style={{ padding: 5 }}>
                      <GetInput
                        inp={question.type}
                        data={question}
                        nav={navigate}
                        options={options}
                        fieldprops={getFieldProps}
                        touched={touched}
                        errors={errors}
                        onFileChange={onChange}
                      />
                    </div>
                  </Grid>
                ))}
                {questionnaire.topic.nextscreen != 'nosubmit' ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <div style={{ padding: 5 }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={onSubmit}
                      >
                        submit
                      </LoadingButton>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
    </div>
  );
}
function RadioInput(e) {
  return (
    <div>
      <p style={{ textAlign: 'left' }}>
        <b>{e.question}</b>
      </p>
      <RadioGroup {...e.getFieldProps(e.iname)} row name={e.iname + '_' + e.id}>
        {e.options.map((prop, key) => (
          <FormControlLabel value={key} control={<Radio />} label={prop} />
        ))}
      </RadioGroup>
    </div>
  );
}
function ImageInput({ e, onChange }) {
  // alert(JSON.stringify(e));

  return (
    <div>
      <HFileReader data={e} iname={e.iname} id={e.id} multiple={e.multiple} onChange={onChange} />
      {/* <input type={'file'} accept="image/jpg,image/png" id={'file' + e.id + e.iname} /> */}
    </div>
  );
}
function TextInput({ e, touched, error, fieldprops }) {
  let n = new Object(touched);
  let err = new Object(error);
  return (
    <TextField
      type={e.type}
      label={e.name}
      fullWidth
      {...fieldprops(e.iname + e.id)}
      // error={Boolean(
      //   n.valueOf(e.iname + e.id).toString() && err.valueOf(e.iname + e.id).toString()
      // )}
      // helperText={n.valueOf(e.iname + e.id).toString() && err.valueOf(e.iname + e.id).toString()}
      style={e.styles.styles}
      name={e.iname + '_' + e.id}
    />
  );
}
function MTextInput({ e, touched, error, fieldprops }) {
  return (
    <TextField
      type="text"
      label={e.name}
      fullWidth
      multiline
      {...fieldprops(e.iname + e.id)}
      // error={true}
      // helperText={true}
      style={e.styles.styles}
      name={e.iname + '_' + e.id}
    />
  );
}
function ImageLink({ e }) {
  const { imgurl } = useAPI();
  const [, forceUpdate] = React.useState({}); // To disable submit button at the beginning.

  let nav = useNavigate();
  React.useEffect(() => {
    forceUpdate({});
  }, []);
  return (
    <div
      onClick={() => {
        const link = '/pageloader/4';
        nav('/pageloader/' + e.styles.link);
      }}
    >
      <Card
        style={{
          backgroundImage: 'url(' + imgurl + e.styles.src + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          flex: 1
        }}
      >
        <div
          style={{
            textAlign: 'center',
            padding: '100px 10px',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.6)',
            justifyContent: 'center',
            color: '#fff'
          }}
        >
          <Typography fontWeight={'bold'}>{e.name}</Typography>
        </div>
      </Card>
    </div>
  );
}
function CardLink({ e }) {
  return <div></div>;
}
function DatabaseSelect({ e, fieldprops }) {
  const { get } = useAPI();

  const [items, setItems] = React.useState([]);
  React.useEffect(() => {
    get(e.styles.src).then((r) => {
      // b.options = r.data;
      setItems(r.data);
    });
  }, []);
  return <SelectInput e={e} options={items} fieldprops={fieldprops} />;
}
function SelectInput({ e, options, fieldprops }) {
  return (
    <FormControl fullWidth style={{ width: '100%' }}>
      <InputLabel id={e.iname}>{e.name}</InputLabel>
      <Select
        style={e.styles.styles}
        name={e.iname + '_' + e.id}
        labelId={e.iname}
        label={e.name}
        fullWidth
        defaultValue={''}
        {...fieldprops(e.iname + '_' + e.id)}
      >
        <MenuItem defaultChecked key={11213} value="">
          Choose Item
        </MenuItem>
        {options.map((prop, key) => (
          <MenuItem key={key} value={prop.value}>
            {prop.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
function ImageDisplay(e) {
  return (
    <div style={e.style.outer}>
      <img src={e.path} style={e.style.element} />
    </div>
  );
}
function Typographer({ e }) {
  return (
    <div>
      <Typography fontWeight={'bold'} style={e.styles.styles}>
        {e.name}
      </Typography>
    </div>
  );
}
function TimeInput({ e }) {
  return (
    <div>
      <TextField
        type="time"
        label={e.name}
        fullWidth
        // error={true}
        // helperText={true}
        style={e.styles.styles}
        name={e.iname + '_' + e.id}
      />
    </div>
  );
}
function CheckboxInput({ e, fieldprops }) {
  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={<Checkbox value="1" name={e.iname + '_' + e.id} />}
          label={e.name}
          {...fieldprops(e.iname + e.id)}
        />
      </FormControl>
    </div>
  );
}
function LocationButton({ e, touched, errorr, fieldprops }) {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  const [lng, setLng] = React.useState('');
  const [lat, setLat] = React.useState('');
  const success = (pos) => {
    const crd = pos.coords;
    console.log('Your current position is:');
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setLat(crd.latitude);
    setLng(crd.longitude);
  };

  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };
  const onClick = () => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  };
  return (
    <div>
      <div>
        <Typography variant="" fontWeight="bold">
          {e.name}
        </Typography>
        <br />
        <br />
      </div>
      <div style={{}}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              placeholder="Latitude, Longitude"
              error={Boolean(touched && errorr)}
              helperText={touched && errorr}
              fullWidth
              label="Latitude, Longitude Coordinates"
              name={e.iname + '_' + e.id}
              value={lat == lng && lat == '' ? lat + '78787,989' + lng : null}
            />
          </Grid>
        </Grid>
        <br />
      </div>
      <Button size="large" fullWidth onClick={onClick} variant="outlined">
        Pick GPS Location
      </Button>
    </div>
  );
}
function GetInput({
  inp,
  errorr,
  touched,
  instanceId,
  data,
  options,
  fieldprops,
  onFileChange,
  nav
}) {
  // alert(JSON.stringify(data));
  switch (inp) {
    case 'text':
      return <TextInput e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'number':
      return <TextInput e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'time':
      return <TextInput e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'date':
      return <TextInput e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'email':
      return <TextInput e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'radio':
      return <RadioInput e={data} fieldprops={fieldprops} />;
    case 'checkbox':
      return <CheckboxInput e={data} fieldprops={fieldprops} />;
    case 'select':
      return <SelectInput e={data} fieldprops={fieldprops} options={options} />;
    case 'file':
      return null;
    case 'location':
      return <LocationButton e={data} fieldprops={fieldprops} touched={touched} error={errorr} />;
    case 'textdisplay':
      return <Typographer e={data} />;
    case 'imagedisplay':
      return <ImageDisplay e={data} />;
    case 'imageinput':
      return <ImageInput e={data} onChange={onFileChange} />;
    case 'multilinetext':
      return <MTextInput e={data} touched={touched} error={errorr} fieldprops={fieldprops} />;
    case 'time':
      return <TimeInput e={data} />;
    case 'imagelink':
      return <ImageLink e={data} nav={nav} />;
    case 'dbselect':
      return <DatabaseSelect e={data} fieldprops={fieldprops} />;
    case 'answerresponsetag':
      return <PageBuilder data={data} />;
    default:
      return null;
  }
}
