import { Card } from '@mui/material';
import * as React from 'react';
import AddApartment from 'src/components/forms/add_apartment';
export default class CreateApartment extends React.Component {
  constructor(prop) {
    super(prop);
  }
  render() {
    return (
      <div>
        <Card>
          <AddApartment />
        </Card>
      </div>
    );
  }
}
