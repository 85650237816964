import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductList(props) {
  return (
    <Grid container spacing={3}>
      {props.products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={4}>
          <ShopProductCard product={product} deleter={props.delete}/>
        </Grid>
      ))}
    </Grid>
  );
}
