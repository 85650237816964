import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import { FoodCard } from 'src/components/hotpot';
import PopularFoods from 'src/hotpotComponents/popularFoods';
export default function CategoryItemsPage() {
  let data = useLocation().state;
  console.log(data);
  return (
    <div>
      <PopularFoods
        sm={6}
        lg={3}
        md={4}
        xs={12}
        data={data.foods}
        cover={data.img}
        title={data.name + ' around your area'}
      />
    </div>
  );
}
