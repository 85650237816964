import { Button, Container, Grid, Rating, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { SlideShow, HouseCard, FoodReviews, ApartmentProvisions } from 'src/components/hotpot';
export default class ApartmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          image: '/static/1.jpg'
        },
        {
          image: '/static/2.jpg'
        }
      ]
    };
  }
  render() {
    return (
      <div>
        <Container>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12} md={5} lg={5}>
              <SlideShow images={this.state.data}></SlideShow>
            </Grid>
            <Grid item sm={12} xs={12} md={7} lg={7}>
              <Stack direction={'column'}>
                <Typography variant="h4">Mariana Trenches</Typography>
                <Typography variant="h5">About</Typography>
                <Typography variant={'body'}>
                  Make money more money more than other money in terms of money and life without
                  money is like money without money
                </Typography>
                <Rating value={4} max={5} />
                <Typography variant="h5" marginBottom={3} marginTop={1}>
                  35$ / Night
                </Typography>
                <div>
                  <ApartmentProvisions />
                </div>
                <div>
                  <br />
                  <Button marginTop={4} variant="contained">
                    Book
                  </Button>
                </div>
                {/* <hr/> */}
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <FoodReviews data={[3, 3, 3, 3, 3]} />
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <Typography variant="h5">Similar places nearby</Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <HouseCard />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
