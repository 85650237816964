import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import FileUpload from "react-mui-fileuploader"
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';
import { HFileReader } from '../hotpot';
import Questionnaire from '../Questionnaire';
import API from 'src/modules/apiClient';

// ----------------------------------------------------------------------

export default function AddApartment(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [supplies, setSupplies] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Apartment title is required'),
    location: Yup.string().required('Apartment location is required'),
    address: Yup.string().required('Apartment address is required'),
    type: Yup.string().required('Apartment type is required'),
    chargeType: Yup.string().required('Apartment Charge type is required'),
    price: Yup.number().required('Apartment Price is required'),
    supplies: Yup.string().required('Supplies are also required'),
    duration: Yup.string().required('duration is required')
  });
  var getDataO = () => {
    return API.connect('apartments/', {
      argument: 'getProvisions'
    }).then((r) => {
      setSupplies(r.data.provisions);
      setLoaded(true);
    });
  };
  if (!loaded) {
    getDataO();
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      address: '',
      supplies: [],
      type: [],
      pictures: [],
      argument: 'addApartment'
      //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      values.push({ files: fieldValue });
      packAPI.addPackage(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleFileUploadError = (error) => {
    // Do something...
  };
  const getSupply = (key) => {
    var prop = null;
    for (var i = 0; i < supplies.length; i++) {
      if (key == supplies[i].id) {
        prop = supplies[i];
      }
    }
    return prop;
  };
  const handleFilesChange = (files) => {
    // Do something...
  };
  const [fieldValue, setFieldValue] = useState({});
  return (
    <FormikProvider value={formik}>
      <Form onLoad={getDataO} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} padding={3}>
          <Typography variant={'h5'}>General Information</Typography>
          <TextField
            type="text"
            label="Apartment name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            type="text"
            label="Apartment Location"
            {...getFieldProps('location')}
            error={Boolean(touched.location && errors.location)}
            helperText={touched.location && errors.location}
          />
          <TextField
            type="text"
            label="Apartment Address"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />
          <Typography variant={'h5'}>Provision Information</Typography>
          <Grid container>
            <Grid item sm={12} lg={12}>
              <Typography>Provisions. What does your apartment offer</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12} marginBottom={1}>
              <Select
                fullWidth
                {...getFieldProps('supplies')}
                multiple
                multiline
                error={Boolean(touched.supplies && errors.supplies)}
                helperText={touched.supplies && errors.supplies}
              >
                {supplies.map((prop) => (
                  <MenuItem value={prop.id}>{prop.name}</MenuItem>
                ))}
                {/* <MenuItem value="TV">TV</MenuItem> */}
                {/* <MenuItem value="Bar">Bar</MenuItem> */}
              </Select>
            </Grid>
          </Grid>
          <Grid container>
            {values.supplies.map((prop) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4} padding={1}>
                <Questionnaire provision={prop} name={getSupply(prop).name} />
              </Grid>
            ))}
          </Grid>
          <Typography variant="h5">Price Information</Typography>
          <Grid container>
            <Grid item sm={12} lg={12}></Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Select
                fullWidth
                {...getFieldProps('type')}
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
              >
                <MenuItem disabled selected value="na">
                  Charge type
                </MenuItem>
                <MenuItem value="night">Nights</MenuItem>
                <MenuItem value="month">Monthly</MenuItem>
                <MenuItem value="year">Yearly</MenuItem>
              </Select>
            </Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <TextField
                style={{ marginLeft: 3 }}
                fullWidth
                type="number"
                label="Price per Charge type"
                {...getFieldProps('duration')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />
            </Grid>
            <Grid item sm={12} lg={12}>
              <Typography variant="h5">Images</Typography>
              <HFileReader multiple={true} sm={12} md={6} lg={4} />
            </Grid>
          </Grid>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>
        <Stack padding={3}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Add Apartment
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
