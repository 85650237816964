import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
import { Icon } from '@iconify/react';
import checkFilled from '@iconify/icons-eva/checkmark-fill'
import trash from '@iconify/icons-eva/trash-2-outline'
// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, deleter }) {
  const { name, price, package_items, duration, id } = product;

  return (
    <Card>
      

      <Stack spacing={2} sx={{ p: 3 }}>
        {/* <Link to="#" color="inherit" underline="hover" component={RouterLink}> */}
          <Typography variant="h2" noWrap>
            {name}
          </Typography> 
          <hr/>
          <Typography variant='h3' noWrap>Features</Typography>
          <Typography variant='h5' noWrap>
          <ul>

          {
            package_items.map((item)=>(
              <li><Icon icon={checkFilled} width={22} height={22} style={{position:"relative",top:6,color:'#ff6f00'}}/>&nbsp;&nbsp;{item.item_text}</li>
              ))
            }
            </ul>
            </Typography>
        {/* </Link> */}
          <hr/>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="h4">
            {fCurrency(price)}
          </Typography>
          <Icon onClick={()=>{deleter(id)}} icon={trash} width={22} height={22} style={{position:"relative",top:6,color:'#ff0000'}}/>
        </Stack>
      </Stack>
    </Card>
  );
}
