import { Card, Grid, Typography } from '@mui/material';
import * as React from 'react'
import { ApartmentLanding, HFooter, HouseCard, SearchApartments } from 'src/components/hotpot';
export default class RentApartments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apartments: []
        }
    }
    render() {
        return (
            <div>
                <ApartmentLanding />
                <Grid container padding={6} spacing={7}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant={"h3"}>Popular Places</Typography>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <HouseCard />
                    </Grid>
                </Grid>
                <HFooter />
            </div>
        )
    }
}