import axios from 'axios';
// const API_URL = 'http://localhost/hotpot/api/';
const API_URL = 'http://192.168.1.110:8000/api/';
export default class API {
  static server_ip = API_URL;

  static ax = axios.create({
    baseURL: `${API_URL}`,
    timeout: 180000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    }
  });
  static connect(endpoint, data) {
    return API.ax.post(endpoint, data).catch((e) => console.log(e));
  }
  static get(endpont) {
    return API.ax.get(endpont).catch((e) => console.log(e));
  }
}
