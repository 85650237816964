import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import { Icon } from '@iconify/react';
import checkFilled from '@iconify/icons-eva/checkmark-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Users',
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'Driver Management',
        path: '/dashboard/drivers',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'User Management',
        path: '/dashboard/user',
        icon: getIcon(peopleFill)
      }
    ]
  },

  {
    title: 'Foods',
    icon: getIcon(shoppingBagFill),
    children: [
      {
        title: 'Food Management',
        path: '/dashboard/food',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Restaurants Management',
        path: '/dashboard/restaurants',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Category Management',
        path: '/dashboard/category',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Order Management',
        path: '/dashboard/orders',
        icon: getIcon(personAddFill)
      },
      {
        title: 'Package Management',
        path: '/dashboard/packages',
        icon: getIcon(shoppingBagFill)
      }
    ]
  },
  {
    title: 'Apartments',
    icon: getIcon(shoppingBagFill),
    children: [
      {
        title: 'Apartments Provisions',
        path: '/dashboard/provisions',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Apartment Management',
        path: '/dashboard/apartments',
        icon: getIcon(shoppingBagFill)
      }
    ]
  },
  {
    title: 'Background Tasks',
    path: '/dashboard/bgtasks',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Bugs & Reviews',
    path: '/dashboard/bugs',
    icon: getIcon(personAddFill)
  }
];

export default sidebarConfig;
