import { Grid } from '@mui/material';
import * as React from 'react';
import { CategoriesH, HFooter, HLoader, isMobile } from 'src/components/hotpot';
import CartWidget from 'src/components/_dashboard/products/ProductCartWidget';
import CustomizeListings from 'src/hotpotComponents/Customize';
import Groceries from 'src/hotpotComponents/Groceries';
import PopularFoods from 'src/hotpotComponents/popularFoods';
import PopularRestaurants from 'src/hotpotComponents/popularRestaurants';
import SpecialDeliveries from 'src/hotpotComponents/specialDeliveries';
import OrderingAPI from 'src/modules/foodOrder';
import LocationTag from '../../hotpotComponents/locationTag';

export default class Foods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      categories: [],
      foods: [],
      special: [],
      restaurants: [],
      isLoading: true,
      superRestaurants: [],
      location: { lng: 36.685, lat: -3.39204 }
    };
  }
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          location: { lng: position.coords.longitude, lat: position.coords.latitude }
        });
      });
    }
    setTimeout(() => {
      OrderingAPI.getHome({
        argument: 'getHome',
        lng: this.state.location.lng,
        lat: this.state.location.lat,
        type: 'user',
        appversion: '2.44.1'
      }).then((r) => {
        var cats = [];
        r.data.categories.line1.map((prop, key) => {
          cats.push(prop);
        });
        r.data.categories.line2.map((prop, key) => {
          cats.push(prop);
        });
        this.setState({
          special: r.data.special_delivery.foods,
          foods: r.data.foods.foods,
          categories: cats,
          restaurants: r.data.restaurants,
          superRestaurants: r.data.super_restaurants,
          isLoading: false
        });
      });
    }, 2000);
  }
  render() {
    return (
      <div>
        <div style={{ backgroundColor: '#eaeaea', paddingTop: 100, paddingBottom: 70 }}>
          <LocationTag data={this.state.superRestaurants} />
          <CartWidget />
          <HLoader isLoading={this.state.isLoading} />
        </div>
        <CategoriesH data={this.state.categories} />
        <Grid container>
          <Grid
            md={7}
            className="scrollbar"
            sm={12}
            xs={12}
            lg={9}
            item
            borderRight="1px solid #eaeaea"
            padding={1}
            style={{ overflowY: 'scroll' }}
            maxHeight={isMobile() ? 'fit-content' : window.outerHeight - 192}
          >
            <PopularFoods
              xs={12}
              sm={6}
              md={6}
              lg={3}
              title="Popular deals on hotpot"
              data={this.state.foods}
            />
          </Grid>
          <Grid
            item
            className="scrollbar"
            lg={3}
            sm={12}
            md={5}
            xs={12}
            padding={1}
            maxHeight={isMobile() ? false : window.outerHeight - 192}
            style={{ overflowY: 'scroll' }}
          >
            <PopularRestaurants data={this.state.restaurants} />
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item lg={4} xs={12} sm={12} md={5} borderRight="1px solid #eaeaea" padding={1}>
            <CustomizeListings />
          </Grid> */}
          <Grid md={12} sm={12} lg={12} item padding={1}>
            <SpecialDeliveries data={this.state.special} />
          </Grid>
          <Grid md={12} sm={12} lg={12}>
            <Groceries data={[]} />
          </Grid>
        </Grid>
        <HFooter />
      </div>
    );
  }
}
