import { Stack, Switch, TextField, Typography, Grid, Button } from '@mui/material';
import * as React from 'react';

export default function ContactForm(props) {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" padding={3}>
            <b>Contact Information</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid padding={3} container spacing={1}>
        <Grid item xs={6} md={6} lg={6}>
          <TextField label="First name" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Last name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Phone Number" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Email Address" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Street Address 1" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Street Address 1" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="House/Apartment Number" />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={12} padding={3}>
          <div>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="body2">
                <b>Create Account</b>
              </Typography>

              <Switch />
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} md={4} marginTop={1}>
          <Button fullWidth variant="contained">
            Save Information
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
