import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import HomepageLayout from './layouts/HomePage';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//Pages
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import HotpotHome from './pages/hotpot/home';
import Restaurant from './pages/Restaurant';
import Orders from './pages/Orders';
import Drivers from './pages/Drivers';
import Packages_ from './pages/Packages';
import HotHome from './pages/users/homePage';
import Foods from './pages/users/foods';
import FoodPage from './pages/users/foodpage';
import RestaurantView from './pages/users/restaurant';
import HotpotCart from './pages/users/cart';
import RentApartments from './pages/users/apartments';
import ApartmentPage from './pages/users/apartmentPage';
import ApartmentsAdmin from './pages/service_providers/house_holder/apartments';
import CreateApartment from './pages/service_providers/house_holder/createApartment';
import FoodsAdmin from './pages/Foods';
import CategoryManager from './pages/categories';
import CategoryItemsPage from './pages/users/categoryItemsPage';
import ApartmentProvisionsManager from './pages/service_providers/house_holder/apartmentProvision';
import ProvisionPage from './pages/service_providers/house_holder/provision';
import VerifyEmail from './components/authentication/register/VerifyEmail';
import ChooseUse from './pages/users/chooseUse';
import HostLayout from './layouts/host';
import RestaurantLayout from './layouts/chef';
import OrderComplete from './pages/users/orderComplete';
import AddFood from './components/forms/add_food';
import HotpotQuestionnaire from './components/forms/questionnaire';
import HFoodPage from './pages/hotpot/foodpage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <HomepageLayout />,
      children: [
        { element: <HotpotQuestionnaire /> },
        { path: 'verify', element: <VerifyEmail /> },
        { path: 'chooseUse', element: <ChooseUse /> },
        { path: 'home', element: <HotHome /> },
        { path: 'pageloader/:uid', element: <HotpotQuestionnaire /> },
        { path: '404', element: <NotFound /> },
        { path: 'about', element: <NotFound /> },
        { path: '/lin', element: <Navigate to="/login" /> }
        // { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/foods',
      element: <HomepageLayout />,
      children: [
        { element: <Navigate to="/foods/home" replace /> },
        { path: 'cart', element: <HotpotCart /> },
        { path: 'thankyou', element: <OrderComplete /> },
        { path: 'home', element: <HotpotHome search={false} /> },
        { path: 'page', element: <FoodPage /> },
        { path: 'categories', element: <CategoryItemsPage /> },
        { path: 'restaurant', element: <RestaurantView /> },
        { path: 'search', element: <Foods search={false} /> },
        { path: 'lin', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/apartments',
      element: <HomepageLayout />,
      children: [
        { element: <Navigate to="/apartments/home" replace /> },
        { path: 'home', element: <RentApartments /> },
        { path: 'page', element: <ApartmentPage /> },
        { path: 'search', element: <RentApartments search={false} /> },
        { path: 'login', element: <Navigate to="login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/search',
      element: <HomepageLayout />,
      children: [
        { element: <Foods /> },
        { path: 'home', element: <Foods /> },
        { path: '404', element: <NotFound /> },
        { path: 'lin', element: <Navigate to="/login" /> },
        { path: '*', element: <Foods /> }
      ]
    },
    {
      path: '/login',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/login/1" replace /> },
        { path: '1', element: <Login /> },
        { path: 'home', element: <HotHome /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        // { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'restaurants', element: <Restaurant /> },
        { path: 'orders', element: <Orders /> },
        { path: 'food', element: <FoodsAdmin /> },
        { path: 'category', element: <CategoryManager /> },
        { path: 'packages', element: <Packages_ /> },
        { path: 'drivers', element: <Drivers /> },
        { path: 'provisions', element: <ApartmentProvisionsManager /> },
        { path: 'apartments', element: <ApartmentsAdmin /> },
        { path: 'provisionPage', element: <ProvisionPage /> },
        { path: 'createApartment', element: <CreateApartment /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/restaurant',
      element: <RestaurantLayout />,
      children: [
        { element: <Navigate to="/restaurant/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'restaurants', element: <Restaurant /> },
        { path: 'orders', element: <Orders /> },
        { path: 'food', element: <FoodsAdmin /> },
        { path: 'food/create', element: <AddFood /> },
        { path: 'category', element: <CategoryManager /> },
        { path: 'packages', element: <Packages_ /> },
        { path: 'drivers', element: <Drivers /> },
        { path: 'provisions', element: <ApartmentProvisionsManager /> },
        { path: 'apartments', element: <ApartmentsAdmin /> },
        { path: 'provisionPage', element: <ProvisionPage /> },
        { path: 'createApartment', element: <CreateApartment /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/host',
      element: <HostLayout />,
      children: [
        { element: <Navigate to="/host/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'apartments', element: <ApartmentsAdmin /> },
        { path: 'createApartment', element: <CreateApartment /> }
      ]
    }
    // { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
