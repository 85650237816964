import API from "./apiClient";

export default class packAPI {
    static listPackages(){
        return API.ax.post(`packages/`,{
            'argument': 'getAll',
        }).catch(e=>{ console.log(e);})
    }
    static deletePackage(e){
        return API.ax.post(`packages/`,{
            argument: 'deletePackage',
            package:e
        }).catch(e=>{ console.log(e);})
    }
    static addPackage(p){
        return API.ax.post(`packages/`,p).catch(e=>{console.log(e);})
    }
}