import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import FileUpload from "react-mui-fileuploader"
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormLabel
} from '@mui/material';
import { LoadingButton, TimePicker } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';
import { HFileReader } from '../hotpot';
import Questionnaire from '../Questionnaire';
import API from 'src/modules/apiClient';
import FoodsApi from 'src/modules/foods';

// ----------------------------------------------------------------------

export default function AddFood(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [supplies, setSupplies] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Food title is required'),
    description: Yup.string().required('Write something about the food i.e Ingredients...'),
    processing: Yup.string().required('Processing time is required'),
    price: Yup.string().required('Food price is required'),
    isSpecial: Yup.string().required('This field is mandatory'),
    categories: Yup.number().required('Choose atleast one category'),
    opening: Yup.string(),
    closing: Yup.string(),
    delivery: Yup.string()
  });
  var getDataO = () => {
    return API.connect('apartments/', {
      argument: 'getProvisions'
    }).then((r) => {
      setSupplies(r.data.provisions);
      setLoaded(true);
    });
  };
  if (!loaded) {
    getDataO();
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      processing: '',
      price: '',
      isSpecial: '',
      opening: '',
      closing: '',
      categories: [],
      images: [],
      delivery: '',
      argument: 'addApartment'
      //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      values.push({ files: fieldValue });
      FoodsApi.connectFoods({}).then((r) => {});
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleFileUploadError = (error) => {
    // Do something...
  };
  const getSupply = (key) => {
    var prop = null;
    for (var i = 0; i < supplies.length; i++) {
      if (key == supplies[i].id) {
        prop = supplies[i];
      }
    }
    return prop;
  };
  const handleFilesChange = (files) => {
    // Do something...
  };
  const [fieldValue, setFieldValue] = useState({});
  return (
    <FormikProvider value={formik}>
      <Form onLoad={getDataO} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} padding={3}>
          <Typography variant={'h5'}>General Information</Typography>
          <TextField
            type="text"
            label="Food name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            type="text"
            label="Processing time"
            {...getFieldProps('processing')}
            error={Boolean(touched.processing && errors.processing)}
            helperText={touched.processing && errors.processing}
          />
          <TextField
            type="text"
            label="Price"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
          />
          <TextField
            type="text"
            label="Description"
            multiline
            rows={6}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
          <Typography variant={'h5'}>Category selection</Typography>
          <Grid container>
            <Grid item sm={12} lg={12}>
              {/* <Typography>Provisions. What does your apartment offer</Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1}>
              <Select
                fullWidth
                {...getFieldProps('categories')}
                multiple
                multiline
                style={{ maxWidth: window.outerWidth - 50 }}
                error={Boolean(touched.categories && errors.categories)}
                helperText={touched.categories && errors.categories}
              >
                {supplies.map((prop) => (
                  <MenuItem value={prop.id}>{prop.name}</MenuItem>
                ))}
                {/* <MenuItem value="TV">TV</MenuItem> */}
                {/* <MenuItem value="Bar">Bar</MenuItem> */}
              </Select>
            </Grid>
          </Grid>
          <Typography variant="h5">Delivery Options</Typography>
          <Grid container>
            <Grid item sm={12} lg={12}></Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              {/* <TimePicker label="Opening Time" /> */}
            </Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <TextField
                style={{ marginLeft: 3 }}
                fullWidth
                type="number"
                label="Price per Charge type"
                {...getFieldProps('duration')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />
            </Grid>
            <Grid item sm={12} lg={12}>
              <Typography variant="h5">Images</Typography>
              <HFileReader multiple={true} sm={12} md={6} lg={4} />
            </Grid>
          </Grid>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>
        <Stack padding={3}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Add Food
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
