import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import close from '@iconify/icons-eva/close-circle-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  FormControlLabel,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ApiData from '../../../modules/apiClient';
import Auth from 'src/modules/authentication';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('Register as Hotpot user');
  const [showError, setShowError] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setLoading(true);
      Auth.login(values.email, values.password, 'none').then((r) => {
        if (r.data.callback == 'success') {
          sessionStorage.setItem('token', r.data.token);
          sessionStorage.setItem('role', r.data.role);
          sessionStorage.setItem('isLogged', '1');
          if (r.data.role == 'admin') {
            navigate('/dashboard', { replace: true });
          } else if (r.data.role == 'user') {
            navigate('/foods/home');
            window.location.reload();
          } else if (r.data.role == 'chef') {
            navigate('/restaurant');
          } else if (r.data.role == 'driver') {
            navigate('/delivery');
          } else if (r.data.role == 'host') {
            navigate('/host');
          }
        } else {
          setLoading(false);
          setShowError(true);
          setError(r.data.return_message);
        }
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {showError ? (
          <Stack
            className="animated tada"
            direction="row"
            justifyContent={'center'}
            marginBottom={2}
          >
            <Alert icon={<Icon icon={close} />} color="error">
              <Typography fontStyle={'bold'} align="center">
                {error}
              </Typography>
            </Alert>
            {/* <Icon icon={close} style={{ marginTop: 1 }} color="red" /> */}
          </Stack>
        ) : null}
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
