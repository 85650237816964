import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import close from '@iconify/icons-eva/close-circle-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Auth from 'src/modules/authentication';
import { appConfig } from 'src/app.json';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('Register as Hotpot user');
  const [showError, setShowError] = useState(false);
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    pwd: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      pwd: '',
      platform: 'web',
      device_id: 'hotpot',
      country: '+255',
      appversion: appConfig.app_version,
      argument: 'register',
      type: 'hotpotWeb',
      phone: 'emptyd'
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setLoading(true);
      setShowError(false);
      Auth.register(values).then((r) => {
        setTimeout(() => {
          setLoading(false);
          var d = r.data;
          if (d.status) {
            setShowError(false);
            navigate('/verify', { replace: true, state: { email: values.email } });
            sessionStorage.setItem('isLogged', '1');
            sessionStorage.setItem('token', d.token);
          } else {
            setError(d.return_message);
            setShowError(true);
          }
        }, 2000);
      });
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {showError ? (
          <Stack
            className="animated tada"
            direction="row"
            justifyContent={'center'}
            marginBottom={2}
          >
            <Icon icon={close} style={{ marginTop: 1 }} color="red" />
            <b>&nbsp;&nbsp;&nbsp;</b>
            <Typography fontStyle={'bold'} align="center" color="red">
              {error}
            </Typography>
          </Stack>
        ) : null}
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('pwd')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.pwd && errors.pwd)}
            helperText={touched.pwd && errors.pwd}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
