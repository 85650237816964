import { useFormik } from 'formik';
import { useState } from 'react';
// material
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import Page from '../components/Page';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';
//
import { Icon } from '@iconify/react';
import PRODUCTS from '../_mocks_/products';
import packAPI from 'src/modules/packages';
import plusFill from '@iconify/icons-eva/plus-fill';
import PopUp from 'src/components/popup/popupmain';
import AddFeature from 'src/components/forms/addpackagefeature';
import AddPAckage from 'src/components/forms/add_package';
// ----------------------------------------------------------------------

export default function Packages_() {
  const [openFilter, setOpenFilter] = useState(false);

  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  if (!isLoaded) {
    packAPI.listPackages().then((r) => setData(r.data));
    setLoaded(true);
  }
  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };
  const deletePackage = (e) => {
    packAPI.deletePackage(e).then((r) => {
      setLoaded(false);
    });
  };
  return (
    <Page title="Dashboard: Products | Hotpot">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Hotpot Package Management
          </Typography>
          <span>
            {/* <div id='div-popup'></div> */}
            <Button
              variant="outlined"
              to="#"
              onClick={() => PopUp.show('Add Package Features', <AddFeature />)}
              startIcon={<Icon icon={plusFill} />}
            >
              Add Features
            </Button>
            &nbsp;
            <Button
              variant="contained"
              to="#"
              onClick={() => PopUp.show('Add Package', <AddPAckage />)}
              startIcon={<Icon icon={plusFill} />}
            >
              New Package
            </Button>
          </span>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            /> */}
            {/* <ProductSort /> */}
          </Stack>
        </Stack>

        <ProductList delete={deletePackage} products={data} />
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ mb: 5 }}
        >
          <Typography variant="h4" gutterBottom>
            Package Features
          </Typography>
        </Stack>
      </Container>
      <PopUp />
    </Page>
  );
}
