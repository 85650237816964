import * as React from 'react';
import { useAPI } from 'src/modules/contenthandler';
import { BarberRequest } from 'src/pages/hotpot/components';
import { Kijiji } from 'src/pages/hotpot/ltip';

export default function PageBuilder({ data }) {
  const { get } = useAPI();
  console.log(data);
  const [ans, setAns] = React.useState([]);
  React.useEffect(() => {
    get(data.styles.src).then((r) => {
      setAns(r.data);
    });
  }, []);

  return ans.map((prop) => <GetComponent variant={data.styles.variant} data={prop} />);
}
function GetComponent({ variant, data }) {
  switch (variant) {
    case 'barberrequest':
      return (
        <div style={{ padding: 3 }}>
          <BarberRequest
            adults={data.adults.answer.textanswer}
            children={data.children.answer.textanswer}
            nail={data.nail.answer.textanswer}
            location={data.location.answer.textanswer}
            date={data.date.answer.textanswer}
            time={data.time.answer.textanswer}
            tag={data.tag}
            mass={data.massage.answer.textanswer}
            med={data.medics.answer.textanswer}
          />
        </div>
      );
    case 'kijiji':
      return (
        <div style={{ padding: 3 }}>
          <Kijiji d={data} />
        </div>
      );
  }
}
