import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';

// ----------------------------------------------------------------------

export default function AddFeature(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [packdata, setPackdata] = useState([]);
  if(packdata.length==0){
    packAPI.listPackages().then(r=>{setPackdata(r.data)})
  }
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    package: Yup.string().required('Package is required'),
    
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      package:'',
      argument: 'addPackageItem'
    //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
        packAPI.addPackage(values)
        // driverAPI.addDriver(values).then((d)=>{
        // })
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Feature Title"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            type="text"
            label="Feature Code"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
          <Select 
          {...getFieldProps('package')}
          error={Boolean(touched.package && errors.package)}
            helperText={touched.package && errors.package}>
              {packdata.map((prop)=><MenuItem value={prop.id}>{prop.name}</MenuItem>
              )}
              {/* <MenuItem value='1'>Motorcycle</MenuItem> */}
              {/* <MenuItem value='2'>Bicycle</MenuItem> */}
          </Select>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Add Feature
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
