import * as React from 'react';
import { Typography, Card, Stack, Button, Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Categories, FoodList, HCarousel } from './components';
import CartWidget from 'src/components/_dashboard/products/ProductCartWidget';
export default function HotpotHome() {
  return (
    <div>
      <CartWidget />
      <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Popular on Hotpot</Typography>
      <HCarousel
        height={400}
        items={[
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 }
        ]}
        variant="Food"
      />
      <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Categories</Typography>
      <Categories
        items={[
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 },
          { name: 'Ugali Ndizi', description: '', rating: 5 }
        ]}
      />
      <FoodList
        items={[
          {
            name: 'Ugali Ndizi',
            description: '',
            rating: 5,
            image: '/static/2.jpg',
            rname: 'Chef Asili',
            price: '14,000',
            distance: '13km',
            info: ['Fixed']
          },
          {
            name: 'Ugali Ndizi',
            description: '',
            rname: 'Chef Asili',
            rating: 5,
            image: '/static/2.jpg',
            price: '12,000',
            info: ['Fixed']
          },
          {
            name: 'Ugali Ndizi',
            description: '',
            rname: 'Chef Asili',
            rating: 5,
            image: '/static/2.jpg',
            price: '12,000',
            info: ['Fixed']
          },
          {
            name: 'Ugali Ndizi',
            description: '',
            rname: 'Chef Asili',
            rating: 5,
            image: '/static/2.jpg',
            price: '12,000',
            info: ['Fixed']
          },
          {
            name: 'Ugali Ndizi',
            rname: 'Chef Asili',
            description: '',
            rating: 5,
            image: '/static/2.jpg',
            price: '12,000',
            info: ['Limited Offer']
          },
          {
            name: 'Ugali Ndizi',
            description: '',
            rating: 5,
            rname: 'Chef Asili',
            image: '/static/2.jpg',
            price: '12,000',
            info: ['Fixed']
          }
        ]}
        title="Trending on Hotpot"
      />
    </div>
  );
}
