import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';

// ----------------------------------------------------------------------

export default function SearchPlace(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    location: Yup.string().required('Location is required'),
    dateIn: Yup.date().required('Check-in date is required'),
    dateOut: Yup.date().required('Check-out date is required'),
    adults: Yup.number().required('Number of adults is required'),
    children: Yup.number().required('Number of Children is required'),
    beds: Yup.number().required('Number of Beds is required')
  });

  const formik = useFormik({
    initialValues: {
      location: '',
      dateIn: '',
      adults: '',
      children: '',
      dateOut: '',
      beds: '',
      argument: 'addPackage'
      //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      packAPI.addPackage(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                type="text"
                fullWidth
                label="Location"
                {...getFieldProps('location')}
                error={Boolean(touched.location && errors.location)}
                helperText={touched.location && errors.location}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                type="date"
                fullWidth
                style={{ margin: 1 }}
                label="Check-in Date"
                {...getFieldProps('dateIn')}
                error={Boolean(touched.dateIn && errors.dateIn)}
                helperText={touched.dateIn && errors.dateIn}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                type="date"
                fullWidth
                style={{ margin: 1 }}
                label="Check-out Date"
                {...getFieldProps('dateOut')}
                error={Boolean(touched.dateOut && errors.dateOut)}
                helperText={touched.dateOut && errors.dateOut}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <TextField
                type="number"
                label="Number of adults"
                fullWidth
                style={{ margin: 1 }}
                {...getFieldProps('adults')}
                error={Boolean(touched.adults && errors.adults)}
                helperText={touched.adults && errors.adults}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <TextField
                type="number"
                label="Number of children"
                fullWidth
                
                style={{ margin: 1 }}
                {...getFieldProps('children')}
                error={Boolean(touched.children && errors.children)}
                helperText={touched.children && errors.children}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <TextField
                type="number"
                label="Number of Beds"
                fullWidth
                defaultValue={1}
                style={{ margin: 1 }}
                {...getFieldProps('beds')}
                error={Boolean(touched.beds && errors.beds)}
                helperText={touched.beds && errors.beds}
              />
            </Grid>
          </Grid>
          {/* <Select
          {...getFieldProps('type')}
          error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}>              
              <MenuItem value='free'>Free</MenuItem>
              <MenuItem value='basic'>Basic</MenuItem>
          </Select> */}
        </Stack>

        <LoadingButton
          fullWidth
          style={{ marginTop: 15 }}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Search Places
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
