import { Icon } from '@iconify/react'
import closecon from '@iconify/icons-ant-design/close';
import { Button, Container, Stack, Typography,Card } from "@mui/material";
import * as React from "react";

export default class PopUp extends React.Component{

    static self
    constructor(props) {
        super(props);
        this.state = {
            title:'No Title',
            body:''
        }
        PopUp.self = this
    }
    static show(title,body){
        PopUp.self.setState({
            title:title,
            body:body
        })
        document.getElementById('div-popup').style.display = 'inherit'
    }

    static dismiss(){
        document.getElementById('div-popup').style.display = 'none'
    }
    render() {
        return (
        <div id={'div-popup'} style={{position:"absolute",display:'none',left:0,right:0,bottom:0, top: '9.0vh', background:'rgba(0,0,0,0.3)',zIndex:100}}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
                    <Card style={{width:"100%"}}>
                        <Container mt={4}>
                            <Button onClick={()=>PopUp.dismiss()} mt={3} style={{float:"right",marginRight:10,marginTop:30}} variant="contained" to="#" startIcon={<Icon icon={closecon}/>}>Close</Button>
                            <br/><br/>
                           
                            <Typography variant="h4" gutterBottom>
                                {this.state.title}
                            </Typography>
                            <div>
                                {this.state.body}
                            </div>
                            <br/>
                            <br/>
                            <br/>
                        </Container>
                    </Card>

                </Stack>
            </Container>
        </div>
        );
    }
}