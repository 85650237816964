import API from './apiClient';

export default class UserAPI {
  static listUsers() {
    return API.ax
      .post(`account/`, {
        argument: 'getAll',
        role: '*',
        uid: '1'
      })
      .catch((e) => {
        console.log(e);
      });
  }
  static listDrivers() {
    return API.ax
      .post(`account/`, {
        argument: 'getAll',
        role: 'driver',
        uid: '1'
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
