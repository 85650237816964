import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';

// ----------------------------------------------------------------------

export default function DriverPlus(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('phone is required'),
    email: Yup.string().required('email is required'),
    rideType:Yup.string().required('Type is required'),
    
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email:'',
      rideType: '',
      argument: 'addDriver'
    //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
        driverAPI.addDriver(values).then((d)=>{
            props.dismiss()
        })
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="text"
            label="Fullname"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            // autoComplete="username"
            type="email"
            label="Email Address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            // autoComplete="username"
            type="text"
            label="Phone Number"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <Select 
          {...getFieldProps('rideType')}
          error={Boolean(touched.rideType && errors.rideType)}
            helperText={touched.phone && errors.phone}>
              <MenuItem value='1'>Motorcycle</MenuItem>
              <MenuItem value='2'>Bicycle</MenuItem>
          </Select>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Add Driver
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
