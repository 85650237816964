import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';

// ----------------------------------------------------------------------

export default function AddPAckage(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Package title is required'),
    type: Yup.string().required('Package type is required'),
    price: Yup.number().required('Price is required'),
    duration: Yup.string().required('duration is required')
  });
  console.log(LoginSchema);
  const formik = useFormik({
    initialValues: {
      name: '',
      duration: '',
      type: '',
      price: '',
      argument: 'addPackage'
      //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      packAPI.addPackage(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Package Title"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            type="number"
            label="Package Price"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
          />
          <TextField
            type="number"
            label="Package Duration"
            {...getFieldProps('duration')}
            error={Boolean(touched.duration && errors.duration)}
            helperText={touched.duration && errors.duration}
          />
          <Select
            {...getFieldProps('type')}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            <MenuItem value="free">Free</MenuItem>
            <MenuItem value="basic">Basic</MenuItem>
          </Select>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Add Package
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
