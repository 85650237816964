import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import Auth from 'src/modules/authentication';
export default function ChooseUse() {
  const [use, setUse] = React.useState('user');
  let navigate = useNavigate();
  const updateRole = () => {
    Auth.updateRole(use).then((r) => {
      var locat = '/';
      if (use == 'chef') {
        locat = '/restaurant';
      } else if (use == 'host') {
        locat = '/host';
      } else if (use == 'driver') {
        locat = '/delivery';
      }
      navigate(locat);
    });
  };
  return (
    <div>
      <Stack style={{ height: window.innerHeight - 120 }} justifyContent="space-between">
        <div></div>
        <Grid container style={{ width: '100%' }}>
          <Grid item xs={1} sm={1} md={4} lg={4}></Grid>
          <Grid item xs={10} sm={10} md={3} lg={3}>
            <Card>
              <div style={{ padding: 13 }}>
                <br />
                <Typography align="center" variant="body1">
                  <b>How would you like to use hotpot</b>
                </Typography>
                <br />
                <RadioGroup
                  onChange={(e) => {
                    setUse(e.target.value);
                  }}
                >
                  <Typography align="left">
                    <label>
                      <Radio value={'user'} />
                      Order food & Rent Apartments
                    </label>
                  </Typography>

                  <Typography align="left">
                    <label>
                      <Radio value={'chef'} />
                      Sell Food & Drinks
                    </label>
                  </Typography>

                  <Typography align="left">
                    <label>
                      <Radio value={'host'} />
                      Host Apartments
                    </label>
                  </Typography>
                </RadioGroup>
                <br />

                <LoadingButton title="Continue" onClick={updateRole} variant="contained" fullWidth>
                  Continue
                </LoadingButton>
              </div>
            </Card>
          </Grid>
          <Grid item xs={1} sm={1} md={3} lg={3}></Grid>
        </Grid>
        <div></div>
      </Stack>
    </div>
  );
}
