import { Grid } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router';
import { FoodCard, FoodCardAlt, RestaurantPageDisplay } from 'src/components/hotpot';
import { ProductCartWidget } from 'src/components/_dashboard/products';
import PopularFoods from 'src/hotpotComponents/popularFoods';
export default function RestaurantView(props) {
  let data = useLocation().state;
  console.log(data);
  return (
    <div>
      <Restaurant data={data} />
    </div>
  );
}
class Restaurant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
  }
  render() {
    return (
      <div>
        <Grid container>
          <Grid item sm={12} md={1} lg={1}></Grid>
          <Grid item sm={12} md={10} lg={10}>
            <RestaurantPageDisplay
              name={this.state.data.name}
              cover={this.state.data.cover}
              logo={this.state.data.logo}
            />
          </Grid>
          <Grid item sm={12} md={1} lg={1}></Grid>
          <Grid item sm={12} md={1} lg={1}></Grid>
          <Grid item sm={12} md={10} lg={10}>
            {/* <Grid container spacing={3}>
              {this.state.data.map((prop, key) => (
                <Grid item sm={12} md={6} lg={4} key={key}>
                  {prop.special === 'false' ? <FoodCard /> : <FoodCardAlt data={prop} />}
                </Grid>
              ))}
            </Grid> */}
            <PopularFoods
              sm={6}
              lg={3}
              md={4}
              xs={12}
              cover={this.state.data.logo}
              title="Menu"
              data={this.state.data.foods}
            />
          </Grid>
        </Grid>
        <ProductCartWidget />
      </div>
    );
  }
}
