import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import home from '@iconify/icons-eva/home-outline';
import info from '@iconify/icons-eva/info-outline';
import fork from '@iconify/icons-ant-design/fire-twotone';
import { Icon } from '@iconify/react';
import checkFilled from '@iconify/icons-eva/checkmark-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Home',
    path: '/pageloader/3',
    icon: getIcon(home)
  },
  {
    title: 'Stock Management',
    path: '/pageloader/19',
    icon: getIcon(info)
  },
  {
    title: 'Login / Register',
    path: '/login',
    icon: getIcon(info)
  }
];

export default sidebarConfig;
