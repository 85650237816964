import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import FileUpload from "react-mui-fileuploader"
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';
import { HFileReader } from '../hotpot';
import ApartmentAPI from 'src/modules/apartments';
import PopUp from '../popup/popupmain';

// ----------------------------------------------------------------------

export default function AddQuestion(props) {
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('title is required'),
    type: Yup.string().required('Question type is required')
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      id: props.supid,
      argument: 'addQz'
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setLoading(true);
      ApartmentAPI.connectApartments(values).then((r) => {
        props.updateIt({ name: values.name, type: values.type });
        setLoading(false);
        PopUp.dismiss();
        formik.resetForm();
      });
    }
  });
  const [loading, setLoading] = useState(false);
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} padding={3}>
          <TextField
            type="text"
            label="name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <Select
            {...getFieldProps('type')}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            <MenuItem value="num">Number</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="bool">Yes/No</MenuItem>
            <MenuItem value="image">Image</MenuItem>
          </Select>
        </Stack>
        <Stack padding={3}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Save Question
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
