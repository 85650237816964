import { Button, Card, Container, Grid, Modal, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import AddProvision from 'src/components/forms/add_provision';
import PopUp from 'src/components/popup/popupmain';
import ApartmentAPI from 'src/modules/apartments';
export default class ApartmentProvisionsManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      provisions: []
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    ApartmentAPI.connectApartments({
      argument: 'getProvisions'
    }).then((r) => {
      var data = r.data;
      this.setState({ provisions: data.provisions, update: false });
      PopUp.dismiss();
    });
  }
  componentDidUpdate() {
    if (this.state.update) {
      this.getData();
    }
  }
  render() {
    return (
      <>
        <div>
          <Container>
            <Button
              onClick={() => {
                PopUp.show(
                  'Create Apartment Provision',
                  <AddProvision
                    updateIt={() => {
                      this.setState({ update: true });
                    }}
                  />
                );
              }}
              variant="contained"
            >
              Create Apartment Provision
            </Button>
            <Grid container marginTop={5}>
              {this.state.provisions.map((prop, key) => (
                <Grid item>
                  <Provisionn name={prop.name} id={prop.id} data={prop} />
                </Grid>
              ))}
            </Grid>
          </Container>
          <PopUp />
        </div>
      </>
    );
  }
}

function Provisionn(prop) {
  let navigate = useNavigate();
  const gotoProvisionPage = (prope) => {
    navigate('/dashboard/ProvisionPage', { state: prope.data });
  };
  return (
    <Button
      onClick={() => {
        gotoProvisionPage(prop);
      }}
      variant="outlined"
      style={{ margin: 1 }}
    >
      {prop.name}
    </Button>
  );
}
