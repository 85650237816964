import { Radio, RadioGroup, TextField, Typography } from '@mui/material';
import * as React from 'react';
import API from 'src/modules/apiClient';
import { HFileReader } from './hotpot';
export default class Questionnaire extends React.Component {
  // const [questions, setQuestions] = React.useState([]);
  constructor(props) {
    super(props);
    this.state = {
      qns: []
    };
    this.getQuestions();
  }
  getQuestions() {
    API.connect('apartments/', {
      argument: 'getQuestionnaire',
      provision: this.props.provision
    }).then((r) => {
      this.setState({ qns: r.data });
    });
  }
  FileInput = () => {
    return <HFileReader />;
  };
  HinputField = (prop) => {
    return <TextField fullWidth type={prop.type} required />;
  };
  BooleanInput = () => {
    return (
      <div>
        <RadioGroup row>
          <label>
            <Typography>
              <Radio value={'yes'} />
              Yes
            </Typography>
          </label>
          <label>
            <Typography>
              <Radio value={'no'} />
              No
            </Typography>
          </label>
        </RadioGroup>
      </div>
    );
  };
  GetQuiz = (props) => {
    var type = props.type;
    console.log(type);
    return (
      <div>
        {type == 'num' ? <this.HinputField type="number" /> : null}
        {type == 'text' ? <this.HinputField type="text" /> : null}
        {type == 'image' ? <this.FileInput sm={12} md={6} lg={6} multiple={false} /> : null}
        {type == 'bool' ? <this.BooleanInput /> : null}
      </div>
    );
  };
  render() {
    return (
      <div
        style={{
          backgroundColor: '#f1f1f1',
          padding: 10,
          borderRadius: 10,
          height: '100%'
        }}
      >
        <Typography variant="body1" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 6 }}>
          <b>{this.props.name}</b>
        </Typography>

        {this.state.qns.map((prop, key) => (
          <div>
            <Typography variant={'body2'}>
              <b>
                <i>
                  {prop.name} {prop.type == 'image' ? '' : '?'}
                </i>
              </b>
            </Typography>
            <this.GetQuiz type={prop.type} />
          </div>
        ))}
      </div>
    );
  }
}
