import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from '../dashboard/Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from '../dashboard/LanguagePopover';
import NotificationsPopover from '../dashboard/NotificationsPopover';
import { Link, Navigate } from 'react-router-dom';
import { isLoggedIn, UserSearchbar } from 'src/components/hotpot';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const isLogged = sessionStorage.getItem('isLogged');
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: '100%'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------
const styles = {};
Navbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
export default function Navbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <img src="/static/logo_main.png" style={{ height: 50 }} />
        <Box sx={{ flexGrow: 1 }} />
        <MHidden width="lgDown">
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 3.5 }}>
            <Link to={'/foods/home'} className={'mnu'}>
              Food &amp; Drinks{' '}
            </Link>
            <Link to={'/apartments'} className="mnu">
              Rent an apartment
            </Link>
            <Link to={'/about'} className="mnu">
              About us
            </Link>

            {isLogged === '1' ? null : (
              <Link to={'/login'}>
                <Button size="medium" variant={'contained'}>
                  Sign In
                </Button>
              </Link>
            )}
          </Stack>
        </MHidden>
        <UserSearchbar />
        {isLogged === '1' ? <AccountPopover /> : null}
      </ToolbarStyle>
    </RootStyle>
  );
}
