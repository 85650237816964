import { CalendarPicker } from '@mui/lab';
import { Grid, Typography, Stack } from '@mui/material';
import * as React from 'react';
import { RestaurantCardAlt } from 'src/components/hotpot';
import CartWidget from 'src/components/_dashboard/products/ProductCartWidget';
export default function PopularRestaurants(props) {
  return (
    <div style={{ width: '100%' }}>
      <div>
        <Typography variant="h5" padding={3}>
          Popular Restaurants
        </Typography>
      </div>
      <div>
        <Grid container justifyContent={'center'} spacing={1}>
          {props.data.map((prop, key) => (
            <Grid item className="hgrid" md={12} key={key}>
              <RestaurantCardAlt data={prop} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
