import { CardContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import Card from 'src/theme/overrides/Card';

function Kijiji({ d }) {
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item>
              <Typography>Name</Typography>
              <Typography>{d.name.answer.textanswer}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export { Kijiji };
