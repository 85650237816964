import * as React from 'react';
import mapboxgl from 'mapbox-gl';

export default function Mapbox(prop) {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(-70.9);
  const [lat, setLat] = React.useState(42.35);
  const [zoom, setZoom] = React.useState(9);
  mapboxgl.accessToken =
    'pk.eyJ1Ijoia3Jvbml4LWRldiIsImEiOiJjbDZ4eGlxcHowazV6M2NtdWwxajZxejBwIn0.lpfh0ulw2L6gayoLPSlr6g';
  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      prop.coordChange(
        map.current.getCenter().lat.toFixed(4),
        map.current.getCenter().lng.toFixed(4)
      );
    });
  });
  return (
    <div
      id="map"
      ref={mapContainer}
      className="map-container"
      style={{
        height: 422,
        borderRadius: 10
      }}
    ></div>
  );
}
