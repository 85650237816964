import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FoodCard } from 'src/components/hotpot';
export default function PopularFoods(props) {
  //   constructor(props) {
  //     super(props);
  //   }
  //   state = {
  //     data: this.props.datae
  //   };
  //   render() {
  return (
    <>
      <div
        className="img-bg"
        style={{
          paddingTop: 30,
          paddingBottom: 30
          // backgroundColor: '#000'
          // backgroundImage: 'url(' + props.cover + ')'
        }}
      >
        <Typography variant="h5" style={{ padding: 15 }} align="center">
          {props.title}
        </Typography>
      </div>
      <Grid container style={{ width: '100%', alignSelf: 'center', margin: 0 }} spacing={3}>
        {props.data.map((prop, key) => (
          <Grid
            key={key}
            item
            lg={props.lg}
            md={props.md}
            sm={props.sm}
            xs={props.xs}
            style={{ paddingLeft: 0, padding: 10 }}
            className="hgrid"
          >
            <FoodCard data={prop} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
// }
