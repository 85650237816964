// import { DateTimePicker } from '@mui/lab';
import { Icon } from '@iconify/react';
import {
  Grid,
  Typography,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Button,
  Collapse,
  RadioGroup,
  Radio,
  TextField,
  Alert,
  Switch
} from '@mui/material';
import { update } from 'lodash';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import info from '@iconify/icons-ant-design/info-circle-filled';
// import * as React from 'react';
import { CartItem } from 'src/components/hotpot';
import Label from 'src/components/Label';
import Mapbox from 'src/components/mapComponent';
import PaymentManager from 'src/hotpotComponents/payment';
import { fCurrency } from 'src/utils/formatNumber';
import OrderAPI from 'src/modules/order';
import API from 'src/modules/apiClient';
import { useAPI } from 'src/modules/contenthandler';
import ContactForm from './contactform';
function Cart(props) {
  const { post, cart, setCart } = useAPI();
  const [deliveryType, setDType] = React.useState('0');
  const incrementIt = (key) => {
    var l = cart;
    l[key].quantity++;
    if (l[key].quantity > 6) {
      l[key].quantity = 6;
    }
    setCart(l);
  };
  const dincrementIt = (key) => {
    var l = cart;
    l[key].quantity--;
    if (l[key].quantity < 0) {
      l[key].quantity = 0;
    }

    setCart(l);
  };
  const getTotal = (key) => {
    var l = cart;
    return l[key].price * l[key].quantity;
  };
  const getSum = () => {
    var total = 0;
    cart.map((prop) => {
      var tt = prop.quantity * prop.price;
      total += tt;
    });
    return total;
  };
  const clearCart = () => {
    setCart([]);
  };
  const buyFood = () => {};
  return (
    <div>
      {cart.length == 0 ? (
        <Goops />
      ) : (
        <div style={{ margin: 10 }}>
          <Grid container spacing={5}>
            <Grid item lg={3} md={12} sm={12}></Grid>
            <Grid item lg={6} md={12} sm={12}>
              <Typography padding={1} variant="h4">
                Your Cart
              </Typography>
              <Grid container rowSpacing={3}>
                <Grid item lg={12} sm={12} md={12} xs={12}></Grid>
                <Grid lg={12} sm={12} md={12} xs={12}>
                  <Card margin={1}>
                    {cart.map((prop, key) => (
                      <CartItem
                        name={prop.name}
                        price={prop.price}
                        quantity={prop.quantity}
                        incrementItem={() => {
                          incrementIt(key);
                        }}
                        decrementItem={() => {
                          dincrementIt(key);
                        }}
                        total={getTotal(key)}
                      />
                    ))}
                  </Card>
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <Card>
                    <Stack padding={3}>
                      <Typography marginTop={3} marginBottom={3} variant="h6">
                        Service Delivery Type
                      </Typography>
                      <Alert
                        title="We currently support delivery services only"
                        color="error"
                        icon={<Icon icon={info} />}
                      >
                        We currently support delivery services only
                      </Alert>
                      <RadioGroup
                        row
                        onChange={(e) => {
                          console.log(e.target.value);
                          // this.setState({ deliveryType: e.target.value });
                        }}
                      >
                        <label>
                          <Radio title="Delivery" checked value="0" placeholder='"Delivery' />
                          Delivery
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <Radio title="Pickup" value="1" disabled />
                          Pickup
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <Radio title="Dine-in" value="2" disabled />
                          Dine-in
                        </label>
                      </RadioGroup>
                      <br />
                      <Typography marginBottom={3} variant="h6">
                        Location
                      </Typography>
                      <Mapbox
                        coordChange={(lat, lng) => {
                          // this.setState({ location: { lat: lat, lng: lng } });
                        }}
                      />
                      {deliveryType != '0' ? null : (
                        <div className="animated flipInX">
                          <Typography marginTop={3} marginBottom={3} variant="h6">
                            Delivery Time
                          </Typography>
                          <Stack marginBottom={3}>
                            <TextField
                              type={'datetime-local'}
                              onChange={(e) => {
                                // this.setState({ deliveryTime: e.target.value });
                              }}
                            />
                          </Stack>
                        </div>
                      )}
                      <TextField
                        multiline
                        rows={2}
                        label="Additional Instructions"
                        onChange={(e) => {
                          // this.setState({ extraInfo: e.target.value });
                        }}
                      />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <div>
                      <ContactForm />
                    </div>
                  </Card>
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <PaymentManager
                    total={getSum()}
                    payChange={(e) => {
                      // this.setState({ paymentMethod: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <Card>
                    <Stack padding={3}>
                      <Typography variant="h5" marginBottom={3}>
                        Order Total
                      </Typography>
                      <Grid container justifyContent={'space-between'}>
                        <Grid item>
                          <Typography variant="body">Food &amp; Drinks</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body">
                            <b>{fCurrency(getSum())} TZS</b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={'space-between'} marginBottom={2}>
                        <Grid item>
                          <Typography variant="body">Delivery Charges</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body">
                            <b>1,000 TZS</b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={'space-between'} spacing={3}>
                        <Grid item sm={12} md={6} lg={6}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            size="large"
                            onClick={clearCart}
                          >
                            Clear
                          </Button>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                          <Button
                            onClick={() => {
                              console.log(this.state);
                              buyFood();
                            }}
                            fullWidth
                            variant="contained"
                            size="large"
                          >
                            Check out
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={3} md={12} sm={12}></Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default function HotpotCart() {
  let navigate = useNavigate();
  navigate('/foods/thankyou');
  return <Cart nav={navigate} />;
}
function Goops() {
  let navigate = useNavigate();
  const goHome = () => {
    navigate('/foods/home/');
  };
  const NoItem = () => {
    return (
      <div
        style={{
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignContent: 'center',
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <div></div>
        <Grid container>
          <Grid item sm={12} md={4} lg={4}></Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Typography textAlign={'center'}>There is no item in your shopping cart</Typography>
            <center>
              <br />
              <br />
              <Button variant={'contained'} marginTop={3} onClick={goHome}>
                Browse Foods
              </Button>
            </center>
          </Grid>
          <Grid item sm={12} md={4} lg={4}></Grid>
        </Grid>
        <div></div>
      </div>
    );
  };
  return <NoItem />;
}
