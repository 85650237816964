import API from './apiClient';
export default class Auth {
  static register(data) {
    return API.ax.post('registration/', data).catch((e) => {
      console.log(e);
    });
  }
  static login(email, pwd, did) {
    return API.ax
      .post('login/', {
        email: email,
        pwd: pwd,
        device_id: did,
        argument: 'login'
      })
      .catch((e) => {
        console.log(e);
      });
  }
  static verifyEmail(email, code) {
    return API.ax
      .post('registration/', {
        code: code,
        email: email,
        argument: 'verifyOTP'
      })
      .catch((e) => {
        console.log(e);
      });
  }
  static updateRole(role) {
    return API.ax.post('registration/', {
      role: role,
      argument: 'updateRole'
    });
  }
  static getToken() {
    return API.ax.post('login/', {
      argument: 'getToken'
    });
  }
  static logout() {
    return API.ax.post('login/', {
      argument: 'logout'
    });
  }
}
