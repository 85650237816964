import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import { GroceryCard } from 'src/components/hotpot'
export default class Groceries extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data: props.data
        }
    }

    render(){
        return(
            <div>
                <Typography padding={3} variant="h5">Groceries</Typography>
                <Grid container spacing={1}>
                    {
                        this.state.data.map((prop)=>(
                        <Grid item lg={3} md={6} sm={6} className="hgrid" style={{paddingLeft:0,padding:30}}>
                            <GroceryCard/>
                        </Grid>
                        ))
                    }
                </Grid>
            </div>
        )
    }
}