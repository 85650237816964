import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import driverAPI from 'src/modules/driver';
import packAPI from 'src/modules/packages';
import API from 'src/modules/apiClient';

// ----------------------------------------------------------------------

export default function AddCategory(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Category title is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      argument: 'add'
      //   remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      API.connect('categories/', values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} direction="row">
          <TextField
            type="text"
            label="Category Title"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <LoadingButton
            // fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Add Category
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
