import * as React from 'react';
import {
  Typography,
  Card,
  Stack,
  Button,
  Grid,
  Rating,
  CardContent,
  Fab,
  Avatar,
  Chip,
  Checkbox
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router';
function showHideLoader(prop) {
  document.getElementById('loading_pane').style.display = prop ? 'block' : 'none';
}
function HCarousel({ items, variant, height }) {
  return (
    <Carousel height={height} swipe animation="slide">
      {items.map((item, i) => (
        <Item key={i} item={item} variant={variant} />
      ))}
    </Carousel>
  );
}
function Item(props) {
  switch (props.variant) {
    case 'Food':
      return <Food item={props.item} variant={props.variant} />;
    case 'Image':
      return <CarouselImage item={props.item} />;
  }
}
function CarouselImage(prop) {
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <div
        style={{
          height: 300,
          width: '100%',
          backgroundImage: 'url(' + prop.item + ')',
          backgroundSize: 'cover',
          borderRadius: 20
        }}
      ></div>
    </div>
  );
}
function RestaurantBanner({ name, logo, location }) {
  return (
    <div>
      <Button fullWidth color="warning" variant="contained">
        <Stack
          direction={'row'}
          spacing={1}
          alignItems="center"
          style={{ padding: 10, borderRadius: 14, width: '100%' }}
        >
          <Avatar src={logo} style={{ width: 50, height: 50 }} />
          <Stack>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body1">{location}</Typography>
          </Stack>
        </Stack>
      </Button>
    </div>
  );
}
function Food(props) {
  return (
    <div>
      <Stack direction="row" justifyContent={'center'}>
        <div style={{ padding: 1, backgroundColor: '#fff', marginTop: 20 }}>
          <Card
            style={{
              width: window.outerWidth - 80,
              backgroundImage: 'url(/static/3.jpg)',
              backgroundSize: 'cover'
            }}
          >
            <div style={{ height: 300 }}></div>
            <div
              style={{
                margin: 5,
                padding: 5,
                backgroundColor: 'rgba(255,255,255,0.1)',
                backdropFilter: 'blur(15px)',
                borderRadius: 10,
                color: '#fff'
              }}
            >
              <Typography variant="h6">{props.item.name}</Typography>
              <Typography
                style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}
              >
                <b>5.0</b>
                <Rating readOnly defaultValue={1} color="primary" max={5} size="small" />
              </Typography>
              <Typography variant="body1">{props.item.description}</Typography>
            </div>
          </Card>
        </div>
      </Stack>
      <Grid container direction={'row'}>
        <Grid item></Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>
    </div>
  );
}
function Categories({ items }) {
  let navigate = useNavigate();
  return (
    <div>
      <Card style={{ backgroundColor: '#ece6cc', margin: 20 }}>
        <CardContent>
          <Grid spacing={4} container>
            {items.map((prop) => (
              <Grid
                onClick={() => {
                  navigate('category/' + prop.id);
                }}
                item
                xs={4}
                justifyContent="center"
                style={{ textAlign: 'center' }}
              >
                <center>
                  <Avatar
                    style={{ width: 70, height: 70, border: '2px solid #ff0000' }}
                    src="/static/1.jpg"
                  />
                </center>
                <Typography>{prop.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
function FoodItem({ item }) {
  return (
    <Card style={{ padding: 10 }}>
      <Stack spacing={1} direction={'row'}>
        <div
          style={{
            backgroundImage: 'url(' + item.image + ')',
            width: 120,
            height: 120,
            borderRadius: 10,
            backgroundSize: 'cover'
          }}
        ></div>
        <div>
          <Stack direction={'column'}>
            <Typography variant="h6">{item.name}</Typography>
            <Typography variant="body1">{item.rname}</Typography>
            <Rating size="small" defaultValue={item.rating} readOnly />
            <Typography variant="body2">{item.price} TZS</Typography>
          </Stack>
          <div style={{}}>
            <Stack></Stack>
            {item.info.map((prop) => (
              <Chip label={prop} color="primary" variant="outlined" size="small" />
            ))}
          </div>
        </div>
      </Stack>
    </Card>
  );
}
function Deliveries({ item }) {
  return (
    <div>
      <Stack>
        <Typography variant="h6">Fixed Ordering Time</Typography>
        <Typography>
          {item.ordstart} - {item.ordend}
        </Typography>
      </Stack>
      <br />
      <Stack>
        <Typography variant="h6">Fixed Delivery Time</Typography>
        <Typography>Deliveries start at {item.deliverstart}</Typography>
      </Stack>
    </div>
  );
}
function FoodList({ items, title }) {
  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h6" fontWeight={'bold'}>
        {title}
      </Typography>
      <Grid container spacing={1}>
        {items.map((prop) => (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <FoodItem item={prop} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
function Review({ item }) {
  return (
    <div>
      <Card>
        <CardContent>
          <Stack direction={'row'} spacing={1}>
            <Avatar />
            <div>
              <Typography>{item.name}</Typography>
              <Rating defaultValue={item.rating} readOnly size="small" />
            </div>
          </Stack>
          <Typography>Comments</Typography>
        </CardContent>
      </Card>
    </div>
  );
}
function ReviewStack(props) {
  return (
    <div>
      <Grid container spacing={1}>
        {props.items.map((prop) => (
          <Grid item xs={12} sm={6} md={4}>
            <Review item={prop} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
function setShowError(substance, message) {
  document.getElementById('mkbt_error').style.display = substance ? 'block' : 'none';
  document.getElementById('mkbt_err_message').innerHTML = message;
}
function BarberRequest({ uname, date, adults, children, nail, tag, location, time, mass, med }) {
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6} md={12} lg={12}>
            <Typography variant="h6">Number of Adults</Typography>
            <Typography variant="body1">{adults}</Typography>
          </Grid>
          <Grid item xs={6} md={12} lg={12}>
            <Typography variant="h6">Number of children</Typography>
            <Typography variant="body1">{children}</Typography>
          </Grid>
          <Grid item xs={6} md={12} lg={12}>
            <Typography variant="h6">
              Nail Clipping {<Checkbox readOnly checked={nail === '1'} />}
            </Typography>

            <Typography variant="body1"></Typography>
          </Grid>
          <Grid item xs={6} md={12} lg={12}>
            <Typography variant="h6">
              Medication
              {<Checkbox readOnly checked={med === '1'} />}
            </Typography>
            <Typography variant="body1"></Typography>
          </Grid>
          <Grid item xs={6} md={12} lg={12}>
            <Typography variant="h6">
              Massage
              {<Checkbox readOnly checked={mass === '1'} />}
            </Typography>
          </Grid>
          <Grid item xs={4} md={12} lg={12}>
            <Typography variant="h6">Date & Time</Typography>
            <Typography variant="body1">
              {date} {time}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography style={{ color: '#999' }} variant="body1">
              OB Tag: {tag}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export {
  HCarousel,
  Categories,
  FoodItem,
  FoodList,
  RestaurantBanner,
  ReviewStack,
  Deliveries,
  showHideLoader,
  setShowError,
  BarberRequest
};
