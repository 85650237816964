import { Container, Stack, Typography, Card, Button } from '@mui/material';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Grid } from '@mui/material';
import { HFooter, HouseCard, RestaurantCardAlt } from 'src/components/hotpot';
import OrderingAPI from 'src/modules/foodOrder';
function Example(props) {
  var items = [
    {
      name: 'Random Name #1',
      description: 'Probably the most random thing you have ever seen!',
      image: '/static/1.jpg'
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!',
      image: '/static/2.jpg'
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!',
      image: '/static/3.jpg'
    }
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <div>
      <Stack direction="row" justifyContent={'center'}>
        <Card style={{ width: '50%' }}>
          <img style={{ width: '100%' }} alt="" src="/static/3.jpg" />
        </Card>
        <div style={{ padding: 10, maxWidth: 500, backgroundColor: '#fff', marginTop: 20 }}>
          <Typography variant="h3">Arusha</Typography>
          <Typography variant="body">
            A Cool region in the north east of Tanzania with lots of cool people who are humble and
            ready for adventure all the time. You can find several national parks while at the core
            of the city and most enjoyable places in earth.
          </Typography>

          <Stack direction="column" marginTop={4}>
            <Button variant="contained">View 18 places</Button>
          </Stack>
        </div>
      </Stack>
      <Grid container direction={'row'}>
        <Grid item></Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>
    </div>
  );
}
export default class HotHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foods: [],
      restaurants: []
    };
  }
  componentDidMount() {
    // var o =window.getComputedStyle("body");
    // console.log("Logged "+o)
    OrderingAPI.getHome({
      argument: 'getHome',
      lng: 36.685,
      lat: -3.39204,
      type: 'user',
      uid: 0,
      appversion: '2.44.1'
    })
      .then((r) => {
        // console.log(r);
        this.setState({ restaurants: r.data.restaurants });
      })
      .catch((e) => { });
  }
  render() {
    return (
      // <Container>
      <div>
        <div
          className="header"
          style={{
            backgroundImage: 'url(/static/vet.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <Grid spacing={3} container>
            <Grid
              className="ther"
              item
              xs={12}
              sm={8}
              style={{ alignSelf: 'center', justifySelf: 'center' }}
            >
              <div style={{ marginBottom: 10 }}>
                <Typography variant={'h4'} align="center">
                  Welcome to hotpot
                </Typography>
                <Typography variant={'body1'} textAlign="center" align="center">
                  Tanzania's leading Food delivery platform
                </Typography>
              </div>
              <center>
                <img src="/static/playst.png" alt="" height={50} />
              </center>
              <div></div>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={3} className="ther">
              {/* <MHidden width="smDown">
                <img src="/static/home.png" className="phn" alt="" />
                <div style={{}}></div>
              </MHidden> */}
              <img src="/static/home1.png" className="phn" alt="" />
              <div style={{}}></div>
              {/* <MHidden>
              </MHidden> */}
            </Grid>
          </Grid>
        </div>
        <div style={{ width: '100%', height: 1, border: '2px solid #ff6f00' }}></div>
        <Stack direction={'column'}>
          <div style={{ backgroundColor: '#efefef', padding: 40 }}>
            <Grid container>
              <Grid item className="ther" width="50%" style={{ alignSelf: 'center' }}>
                <div style={{ padding: 40 }}>
                  <Typography variant="h1" align={'center'}>
                    What we do
                  </Typography>
                  <center>
                    <Typography variant="body" align="center">
                      Hotpot is a software as a service app for Food ordering and platform offering
                      variety of restaurants near your location
                    </Typography>
                  </center>
                </div>
              </Grid>
              <Grid className="ther" item width={'50%'}>
                <img src="/static/illustrations/6.png" alt="" style={{ width: '97%' }} />
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: 30 }}>
            <Typography align="center" style={{ padding: 30 }} variant={'h1'}>
              What we offer{' '}
            </Typography>
            <Grid container justifyContent={'center'} spacing={3}>
              <Grid item>
                <Card style={{ width: '100%', padding: 30 }}>
                  <Stack direction={'column'} justifyContent="center">
                    <img src="/static/illustrations/1.png" alt="" className="ill-img" />
                    <Typography variant={'h4'} align={'center'}>
                      Variety of foods
                    </Typography>
                    <Typography variant="body" maxWidth={300} align="center">
                      We have prepared a variet of foods to meet your demand
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item>
                <Card style={{ width: '100%', padding: 30 }}>
                  <Stack direction={'column'} justifyContent="center">
                    <img src="/static/illustrations/4.png" alt="" className="ill-img" />
                    <Typography variant={'h4'} align={'center'}>
                      Collection of restaurants
                    </Typography>
                    <Typography variant="body" align="center" maxWidth={300}>
                      The Restaurant processes your order
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item>
                <Card style={{ width: '100%', padding: 30 }}>
                  <Stack direction={'column'} justifyContent="center">
                    <img src="/static/illustrations/2.png" alt="" className="ill-img" />
                    <Typography variant={'h4'} align={'center'}>
                      Lightspeed delivery
                    </Typography>
                    <Typography variant="body" align="center" maxWidth={300}>
                      We deliver hot food to our customers. hotpot offers superfast delivery of your
                      food. We like to keep it hot!
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item>
                <Card style={{ width: '100%', padding: 30 }}>
                  <Stack direction={'column'} justifyContent="center">
                    <img src="/static/illustrations/5.png" alt="" className="ill-img" />
                    <Typography variant={'h4'} align={'center'}>
                      Dine-in Service
                    </Typography>
                    <Typography variant="body" align="center" maxWidth={300}>
                      We deliver hot food to our customers. hotpot offers superfast delivery of your
                      food. We like to keep it hot!
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div style={{ backgroundColor: '#000' }}>
            <div style={{ padding: 50 }}>
              <Typography color={'#fff'} variant="h1" align="center">
                We have space for restaurants
              </Typography>
              <center style={{ marginTop: 40 }}>
                <Typography align="center" color={'#fff'} variant="body">
                  Do you own a restaurant and wish to extend reachability to more customers, well we
                  have your back. sign up as a restaurant today, Its free
                </Typography>
              </center>
              <center style={{ marginTop: 40 }}>
                <Button variant="outlined">Sign up as a business</Button>
              </center>
            </div>
          </div>
        </Stack>
        <Container
          maxWidth="x1"
          style={{ marginTop: 60, alignContent: 'center', alignItems: 'center' }}
        >
          <Typography variant="h3" align="center" style={{ marginBottom: 40 }}>
            Popular Restaurants
          </Typography>
          <Stack direction={'row'}>
            {/* <center> */}
            <Grid
              container
              spacing={2}
              justifyContent={'center'}
              alignContent="center"
              alignSelf={'center'}
            >
              {this.state.restaurants.map((prop) => (
                <Grid item alignSelf={'center'} sm={12} md={4} lg={3}>
                  <RestaurantCardAlt data={prop} />
                </Grid>
              ))}
            </Grid>
            {/* </center> */}
          </Stack>
          <center>
            <Button
              variant="text"
              to="#"
              style={{
                alignSelf: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                marginTop: 10
              }}
            >
              Load More...
            </Button>
          </center>

          <Box marginTop={10} marginBottom={10}>
            <Typography align="center" variant="h2">
              Introducing Hotstay
            </Typography>
            <Typography align="center">
              This feature lets you find apartments, homes and hotels on demand
            </Typography>
          </Box>
          <Example />
          <Box marginTop={3} marginBottom={5}>
            <Typography variant="h3" align="center">
              Featured places
            </Typography>
          </Box>

          <Grid
            container
            spacing={1}
            justifyContent={'center'}
            alignContent="center"
            alignSelf={'center'}
          >
            <Grid item sm={12} md={4} lg={4}>
              <HouseCard />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <HouseCard />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <HouseCard />
            </Grid>
          </Grid>

          <Box margin={1} marginTop={6}>
            <center>
              <Button style={{ margin: 'auto' }} variant="contained">
                View More
              </Button>
            </center>
          </Box>

          <Box>
            <Typography variant={'h1'}>Number of users</Typography>
          </Box>
        </Container>

        <div>
          <HFooter />
        </div>
      </div>
      // </Container>
    );
  }
}
