import { motion } from 'framer-motion';
import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { MotionContainer, varBounceIn } from 'src/components/animate';
import { Link } from 'react-router-dom';

export default function OrderComplete() {
  return (
    <div>
      <MotionContainer initial="initial" open>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div style={{ height: 200 }}></div>
            <Stack style={{ padding: 20 }}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                <motion.div variants={varBounceIn}>
                  <b>
                    Order Has been Completed, Thank you for choosing hotpot. Your order will be
                    delivered shortly.
                    <br />
                    <br />
                    <br />
                  </b>
                </motion.div>
              </Typography>
              <motion.div
                variants={varBounceIn}
                style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}
              >
                <Link to="/foods/home">
                  <Button size="large" variant="contained">
                    Continue Shopping
                  </Button>
                </Link>
              </motion.div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </MotionContainer>
    </div>
  );
}
