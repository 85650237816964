import { Button, Card } from '@mui/material';
import * as React from 'react';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-eva/plus-outline';
import { Link } from 'react-router-dom';

export default class ApartmentsAdmin extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Link to="/dashboard/createApartment">
          <Button
            marginBottom={12}
            startIcon={<Icon icon={plus} color="#ff6f00" />}
            variant="outlined"
          >
            Add new Apartment
          </Button>
        </Link>
        <Card></Card>
      </div>
    );
  }
}
