import { Card, Typography, Stack, RadioGroup, Radio, TextField } from '@mui/material';
import * as React from 'react';
import API from 'src/modules/apiClient';
export default class PaymentManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paym: []
    };
  }
  componentDidMount() {
    API.connect('config/', { argument: 'getPaymentMethods' }).then((r) => {
      this.setState({ paym: r.data });
    });
  }
  render() {
    return (
      <div>
        <Card>
          <Stack padding={3}>
            <Typography variant="h6" marginBottom={3}>
              Payment Method
            </Typography>
            <RadioGroup row onChange={this.props.payChange}>
              {this.props.total < 5000 ? (
                <>
                  <label className="animate bounce">
                    <Radio value="cash" />
                    Cash
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </>
              ) : null}
              {this.state.paym.map((prop, key) => (
                <label>
                  <Radio value={prop.id} />
                  {prop.name}
                </label>
              ))}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <Radio value="Paypal" />
                Paypal
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <Radio value="dpo" />
                DirectPay Online
              </label> */}
            </RadioGroup>
            {/* <Typography variant="h6">Coupon Code</Typography> */}
            <TextField label="Coupon Code"></TextField>
          </Stack>
        </Card>
      </div>
    );
  }
}
