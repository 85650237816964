import {
  Avatar,
  Badge,
  BadgeRoot,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import * as React from 'react';
import AddCategory from 'src/components/forms/add_category';
import CategoryAPI from 'src/modules/categories';
export default class CategoryManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    CategoryAPI.getCategories({
      argument: 'getCats',
      lng: 0,
      id: 0,
      lat: 0,
      type: 'admin'
    }).then((r) => {
      this.setState({ data: r.data });
    });
  }
  render() {
    return (
      <Container>
        <AddCategory />
        <Grid container marginTop={1} spacing={1}>
          {this.state.data.map((prop, key) => (
            <Grid item>
              <Card padding={2}>
                <Stack
                  direction={'row'}
                  alignSelf="center"
                  justifyContent={'center'}
                  alignItems="center"
                  justifySelf={'center'}
                  spacing={1}
                  padding={2}
                >
                  <Badge badgeContent={prop.total} color="error">
                    <Avatar alt={prop.name} src={prop.image} />
                  </Badge>
                  <Typography>{prop.name}</Typography>
                  <div></div>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
}
