import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FoodCard, FoodCardAlt } from 'src/components/hotpot';
export default function SpecialDeliveries(props) {
  return (
    <>
      <Typography variant="h5" style={{ padding: 10 }}>
        Special Time Deliveries
      </Typography>
      <Grid container spacing={3}>
        {props.data.map((prop) => (
          <Grid item md={3} lg={3} sm={12} className="hgrid">
            <FoodCardAlt data={prop} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
