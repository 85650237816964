import { Card, Grid, Typography } from '@mui/material'
import { random, size } from 'lodash';
import * as React from 'react'
export default class CustomizeListings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ["s", "d", "s", "d", "s", "z", "y", "s", "d", "s", "d", "s", "z", "y", "s", "d", "s", "d", "s", "z", "y", "s",]
        }
    }
    generateRandomNumber() {
        var sizes = [2, 3, 4, 6]
        var colors = ["ffccbb", "ffddff", "aaddff", "ccffaa", ""]
        var min = 0;
        var ndom = Math.random(3, 2)
        if (ndom > 0 && ndom < 0.25) {
            min = 0;
        }
        else if (ndom > 0.25 && ndom < 0.5) {
            min = 1;
        }
        else if (ndom > 0.5 && ndom < 0.75) {
            min = 2;
        }
        else {
            min = 3;
        }
        return sizes[min]
    }
    CustomizeCard() {
        const size = this.generateRandomNumber();
        return (
            <Grid item md={size} lg={size} sm={size}>
                <Card style={{ padding: 10, margin: 5, backgroundColor: "#ccffaa" }}>
                    <Typography variant="h6" align="center">Yellow</Typography>
                </Card>
            </Grid>
        )
    }
    render() {
        return (
            <div>
                <Typography style={{ padding: 10 }} variant="h5">Tell us what you like to eat</Typography>
                <Grid container>
                    {
                        this.state.data.map((prop) => (
                            this.CustomizeCard()
                        ))
                    }
                </Grid>
            </div>
        )
    }
}