import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import AddPAckage from 'src/components/forms/searchplace';
import ChooseLocation from 'src/components/forms/chooseLocation';
import { CategoriesH } from 'src/components/hotpot';
import HotSpecial from './hotpotTrendingSlider';
export default class LocationTag extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <div className="chooseL">
                <Typography variant="h3">Find Awesome Deals in Tanzania</Typography>
                <Typography variant="body">
                  List of top restaurants, caf, Pubs and bars based on trends
                </Typography>
                <Stack direction="row"></Stack>
              </div>
              <center>
                <Grid container>
                  <Grid item xs={1} sm={3} lg={3}></Grid>
                  <Grid item xs={10} sm={6} lg={6}>
                    <Button variant="contained" size="large" fullWidth>
                      Pick Location
                    </Button>
                  </Grid>
                  <Grid item xs={1} sm={3} lg={3}></Grid>
                </Grid>
              </center>
              {/* <ChooseLocation /> */}
            </Grid>
            <Grid item width={500} maxWidth={3000}>
              <HotSpecial data={this.props.data} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
