import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import { Icon } from '@iconify/react';
import checkFilled from '@iconify/icons-eva/checkmark-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/restaurant/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Foods',
    icon: getIcon(shoppingBagFill),
    children: [
      {
        title: 'Food Management',
        path: '/restaurant/food',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Package Management',
        path: '/restaurant/packages',
        icon: getIcon(shoppingBagFill)
      }
    ]
  },
  {
    title: 'Order Management',
    path: '/restaurant/orders',
    icon: getIcon(personAddFill)
  }
];

export default sidebarConfig;
