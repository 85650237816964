import {
  Container,
  Slide,
  Stack,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardMedia,
  Rating,
  Button,
  Badge,
  Fab,
  CardHeader,
  Chip,
  Avatar,
  CardContent,
  CircularProgress,
  TextField,
  FilledInput
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Box, Grid } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import plusFill from '@iconify/icons-eva/plus-fill';
import cart from '@iconify/icons-eva/shopping-cart-outline';
import heart from '@iconify/icons-eva/heart-fill';
import heartO from '@iconify/icons-eva/heart-outline';
import message from '@iconify/icons-eva/message-circle-outline';
import chevronr from '@iconify/icons-eva/chevron-right-outline';
import arrowr from '@iconify/icons-eva/arrow-right-fill';
import SendIcon from '@iconify/icons-ant-design/send';
import SearchIcon from '@mui/icons-material/Search';
import minimize from '@iconify/icons-eva/minus-outline';
import wifi from '@iconify/icons-ant-design/wifi-outlined';
import ig from '@iconify/icons-ant-design/instagram';
import upload from '@iconify/icons-ant-design/upload-outlined';
import { Link, Navigate } from 'react-router-dom';
import { width } from '@mui/system';
import searchFill from '@iconify/icons-eva/search-fill';
// material;
import { useNavigate } from 'react-router-dom';
import { Input, InputAdornment, ClickAwayListener } from '@mui/material';
import SearchPlace from './forms/searchplace';
import { fa } from 'faker/lib/locales';
import { parseJSON } from 'date-fns';
import { fCurrency } from 'src/utils/formatNumber';
import Label from './Label';
import ShopProductCard from './ProductCard';
import API from 'src/modules/apiClient';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const addToCart = (props) => {
  var cart = sessionStorage.getItem('cart');
  var json = JSON.parse(cart);
  var orderItem = {
    name: props.name,
    quantity: 1,
    rid: props.restaurant.id,
    type: 'food',
    price: props.price,
    id: props.id
  };
  props.quantity = 1;
  json.push(orderItem);
  var vart = JSON.stringify(json);
  sessionStorage.setItem('cart', vart);
};

const getCartSize = () => {
  // setTimeout;
  var size = 0;
  try {
    var cart = sessionStorage.getItem('cart');
    var jon = JSON.parse(cart);
    size = jon.length;
  } catch (e) {}
  // var jo =[]
  return size;
};
// ----------------------------------------------------------------------
const addToFavourites = (fid) => {
  API.connect('foods/', {
    fid: fid
  });
};
function isMobile() {
  if (window.outerWidth > 500) {
    return false;
  } else {
    return true;
  }
}
function reverseGeocode(coords) {
  fetch(
    'http://nominatim.openstreetmap.org/reverse?format=json&lon=' +
      coords.lon +
      '&lat=' +
      coords.lat
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      console.log(json);
    });
}
function HFileReader(props) {
  const [imageFiles, setImageFiles] = useState([]);
  const [images, setImages] = useState([]);
  // alert(JSON.stringify(props.data.e));
  const changeHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      imageFiles.map((fil) => {
        validImageFiles.push(fil);
      });
      setImageFiles(validImageFiles);
      props.onChange(validImageFiles, [props.iname + '_' + props.id]);
      return;
    }
    alert('Selected images are not of valid type!');
  };
  const updImages = () => {};
  useEffect(() => {
    const images = [],
      fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);
  return (
    <div className="App">
      {/* <form> */}

      <Stack direction={'row'} marginBottom={3}>
        <input
          type="file"
          name={props.iname + props.id}
          id={props.iname + props.id}
          onChange={changeHandler}
          accept="image/png, image/jpg, image/jpeg"
          multiple={props.multiple === '1'}
          style={{ display: 'none' }}
        />
      </Stack>

      {/* </form> */}
      <Grid container spacing={2}>
        {images.map((image, idx) => {
          return (
            <Grid item key={idx} xs={6} sm={4} md={4} lg={4}>
              <Card>
                <img style={{ width: '100%', height: 'auto' }} src={image} alt="" />{' '}
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={6} sm={4}>
          <label htmlFor={props.iname + props.id}>
            <Stack
              style={{
                width: '100%',
                height: 200,
                backgroundColor: '#ff6f00',
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ fontSize: 30, fontWeight: 'bold', color: '#fff' }}>+</div>
            </Stack>
          </label>
        </Grid>
      </Grid>
    </div>
  );
}
function SlideShow(props) {
  var items = props.images;
  return (
    <Carousel animation="slide" width={'100%'} height={400}>
      {items.map((item, i) => (
        <Card>
          <div
            style={{ backgroundImage: 'url(' + item.image + ')', width: '100%', height: 400 }}
            className="img-bg"
          ></div>
        </Card>
      ))}
    </Carousel>
  );
}
function HLoader(props) {
  if (props.isLoading) {
    document.getElementById('htmlParent').style.overflowY = 'none';
  } else {
    document.getElementById('htmlParent').style.overflowY = 'auto';
  }
  const LoadingImage = () => {
    return (
      <>
        <CircularProgress
          thickness={1}
          size={90}
          style={{ backgroundColor: '#fff', borderRadius: '50%' }}
        />

        {/* <div className="hloader rotation"></div> */}
        <img src="/static/logo_main.png" className="hloadimage" />
      </>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 7200,
        backdropFilter: 'blur(10px)',
        boxShadow: '2px 2px #000',
        display: props.isLoading ? 'block' : 'none',
        transition: '2s'
      }}
    >
      <Stack direction="column" justifyContent={'space-between'} style={{ height: '100%' }}>
        <div></div>
        <div>
          <Grid container>
            <Grid item xs={3} sm={4} md={4} lg={4}></Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <center>
                <LoadingImage />
              </center>
            </Grid>
            <Grid item xs={3} sm={4} md={4} lg={4}></Grid>
          </Grid>
        </div>
        <div></div>
      </Stack>
    </div>
  );
}
function SlideItem(props) {
  return (
    <div>
      <Stack direction="row" justifyContent={'center'}>
        <Card style={{ width: '50%' }}>
          <img style={{ width: '100%' }} alt src="/static/3.jpg" />
        </Card>
        <div style={{ padding: 10, maxWidth: 500, backgroundColor: '#fff', marginTop: 20 }}>
          <Typography variant="h3">Arusha</Typography>
          <Typography variant="body">
            A Cool region in the north east of Tanzania with lots of cool people who are humble and
            ready for adventure all the time. You can find several national parks while at the core
            of the city and most enjoyable places in earth.
          </Typography>
          <Stack direction="column" marginTop={4}>
            <Button variant="contained">View 18 places</Button>
          </Stack>
        </div>
      </Stack>
      <Grid container direction={'row'}>
        <Grid item></Grid>
        <Grid item>
          <div></div>
        </Grid>
      </Grid>
    </div>
  );
}
function UserSearchbar() {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [keyword, setKeyword] = useState('');

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              onChange={(s) => {
                setKeyword(s.target.value);
              }}
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Link to={'search/?q=' + keyword}>
              <Button variant="contained" onClick={handleClose}>
                Search
              </Button>
            </Link>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
function FoodCard(prop) {
  let navigate = useNavigate();
  const gotoFood = () => {
    navigate('/foods/page/', { state: { data: prop.data } });
  };
  const image = prop.data.image[0].name;
  const food = {
    name: prop.data.name,
    price: prop.data.price,
    priceSale: prop.data.price,
    status: 'sele',
    image: image
  };
  return (
    <Card style={{ width: '100%' }}>
      <CardMedia>
        <div
          style={{
            maxHeight: 650,
            overflow: 'hidden',
            justifyContent: 'center',
            justifyItems: 'center',
            justifySelf: 'center'
          }}
        >
          <div
            style={{
              width: '100%',
              backgroundImage: `url(${image})`,
              backgroundPositionY: 'center',
              backgroundSize: 'cover'
            }}
          >
            <div style={{ paddingTop: 250 }}>
              <div
                style={{
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  padding: 10,
                  backfaceFilter: 'blur(31px)'
                }}
              >
                <Stack direction={'row'} justifyContent="space-between" justifySelf={'center'}>
                  <Stack direction={'column'}>
                    <Typography variant="h6">{prop.data.name}</Typography>
                    <Typography variant="body" fontStyle={'italic'}>
                      {prop.data.restaurant.name}
                    </Typography>
                    <Stack direction={'row'}>
                      <Rating value={prop.data.rating} readOnly size="small" />
                      <p>&nbsp;&nbsp;</p>
                      <Icon icon={heart} fontSize={18} color="#ff06a8" />
                      <p>&nbsp;&nbsp;</p>
                      <Typography variant="body">{prop.data.likes}</Typography>
                    </Stack>

                    {/* <Typography variant='body'>order food from popular restaurants around your area. We have collected different restaurants of different sizes which provide food delivery sevice</Typography> */}
                    <div>
                      <Typography variant="h5">{Number(prop.data.price).toFixed(2)}</Typography>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent={'space-between'}>
                  {/* <Link to="/foods/page"> */}
                  <Button
                    onClick={gotoFood}
                    variant="outlined"
                    to="#"
                    endIcon={<Icon icon={chevronr} />}
                  >
                    View
                  </Button>
                  {/* </Link> */}
                  <Button
                    variant="contained"
                    onClick={() => {
                      addToCart(prop.data);
                    }}
                    to="/page"
                  >
                    <Icon icon={cart} />
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </CardMedia>
    </Card>
  );
}
function RestaurantCard(props) {
  let navigate = useNavigate();
  const gotoRestaurant = () => {
    navigate('/foods/restaurant/', { state: props.data });
  };
  return (
    <Card onClick={gotoRestaurant} style={{ width: '100%' }}>
      <CardMedia>
        <div
          style={{ backgroundImage: 'url(/static/2.jpg)', height: 400, width: '100%' }}
          className={'img-bg'}
        >
          <div className="lc">
            <Stack className="lcc" direction={'row'} padding={1} alignItems={'center'}>
              <div className="ci">
                <img src="/static/1.jpg" className="logo-resty" />
              </div>
              <div style={{ padding: 10 }}>
                <Typography variant="h4">{props.data.name}</Typography>
                <Typography>{props.data.name}</Typography>
                <Rating value={4} readOnly size="small" />
                <div></div>
              </div>
            </Stack>
          </div>
        </div>
      </CardMedia>
    </Card>
  );
}
function RestaurantCardAlt(prop) {
  let navigate = useNavigate();
  const gotoRestaurant = () => {
    navigate('/foods/restaurant/', { state: prop.data });
  };
  return (
    <Card style={{ width: '100%' }}>
      <Stack
        direction={'row'}
        alignSelf={'center'}
        justifySelf="center"
        alignItems={'center'}
        padding={1}
        width={'100%'}
      >
        <div className="ci">
          <img src="/static/1.jpg" className="logo-resty" />
        </div>
        <div style={{ padding: 4 }}>
          <Typography variant="h6">{prop.data.name}</Typography>
          <Typography>{prop.data.location}</Typography>
          <Rating value={prop.data.rating.rating} readOnly size="small" />
          <div>
            <Button variant="outlined" onClick={gotoRestaurant} endIcon={<Icon icon={chevronr} />}>
              View Menu
            </Button>
          </div>
        </div>
      </Stack>
      <CardMedia></CardMedia>
    </Card>
  );
}
function HouseCard() {
  return (
    <Card style={{ width: '100%' }}>
      <Stack direction="column">
        <img src="/static/2.jpg" style={{ width: '100%' }} />
        <div style={{ margin: 10 }}>
          <Typography variant="h4">Mariana suite</Typography>
          <Typography variant="body">
            Best place in Arusha, they even got a fish and whale swimming pool you should really go
            here
          </Typography>
          <div>
            <Rating value={5} />
          </div>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography variant="h6">25$ /night</Typography>
            <Button
              color="primary"
              onClick={() => <Navigate to={'apartment'} />}
              variant="contained"
            >
              View
            </Button>
          </Stack>
        </div>
      </Stack>
    </Card>
  );
}
function GroceryCard() {
  return (
    <div>
      <Card>
        <div
          className="img-bg"
          style={{ height: 300, width: '100%', backgroundImage: 'url(/static/1.png)' }}
        ></div>
      </Card>
      <div style={{ marginTop: 10 }}>
        <Typography variant={'h5'} align="center">
          Mahindi
        </Typography>
        <Typography variant={'h6'} align="center">
          1,000 TZS
        </Typography>
      </div>
    </div>
  );
}
function HFooter() {
  return (
    <div style={{ backgroundColor: '#000000', width: '100%', marginTop: 30, color: 'white' }}>
      <div style={{ backgroundColor: '#1b1b1b', padding: 40 }}>
        <Grid container>
          <Grid item width="33%" className="ther">
            <Typography variant="h4">Get in touch</Typography>
            <Typography maxWidth={350}>Email: ask@hotpot.co.tz</Typography>
            <Typography maxWidth={350}>Phone Number: +255 683 311 323</Typography>
            <Typography maxWidth={350}>Office: Makongoro rd, Arusha Tanzania</Typography>
          </Grid>
          <Grid item width="33%" className="ther">
            <Typography align="center" variant="h4">
              Social Media
            </Typography>
            <Stack direction="row" justifyContent={'center'}>
              <a href="https://instagram.com/">
                <Icon icon={ig} fontSize={30} color="#fff" />
              </a>
            </Stack>
          </Grid>
          <Grid item width="33%" className="ther">
            <Typography align="right" variant="h4">
              Important Links
            </Typography>
            <Stack direction="column" justifyContent={'flexEnd'} alignSelf="flex-end">
              <Link to="policy/">
                <Typography align="right" color="#fff">
                  Privacy policy
                </Typography>
              </Link>
              <Link to="policy/">
                <Typography align="right" color="#fff">
                  Terms and Conditions
                </Typography>
              </Link>
              <Link to="policy/">
                <Typography align="right" color="#fff">
                  About hotpot
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: 15 }}>
        <Typography variant="h5" align="center">
          &copy; Developed by Dhukitech.LLC
        </Typography>
      </div>
    </div>
  );
}
function CategoriesH(props) {
  let navigate = useNavigate();
  const Category = (prop) => {
    const gotoCategory = (propaganda) => {
      navigate('/foods/categories', { state: propaganda });
    };
    return (
      <div
        key={prop.kkey}
        onClick={() => {
          gotoCategory(props);
        }}
        style={{ backgroundColor: '#efefef', padding: 7, borderRadius: 7, height: '100%' }}
      >
        {/* <Link to="/foods/categories" state={{ data: prop.data }}> */}
        {/* <Button
          variant="outlined"
          onClick={() => {
            gotoCategory(prop.data);
          }}
        > */}
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} lg={2} md={2}>
            <center>
              <Avatar src={prop.data.img} />
            </center>
          </Grid>
          <Grid item xs={12} sm={12} lg={10} md={10}>
            <Typography variant="body2" style={{ padding: 10, color: '#000' }} textAlign="center">
              <b>{prop.data.name}</b>
            </Typography>
          </Grid>
        </Grid>
        <Stack direction={'row'} justifyContent="center" alignItems={'center'}></Stack>
        {/* </Button> */}
        {/* </Link */}
      </div>
    );
  };
  return (
    <div style={{ margin: 6 }}>
      <Typography variant={'h6'} textAlign="center" padding={1}>
        Browse popular categories
      </Typography>
      <Grid
        container
        direction={'row'}
        className="scrollbar"
        // style={{ overflowX: 'scroll' }}
        spacing={1}
      >
        {props.data.map((prop, key) => (
          <Grid item xs={6} sm={4} md>
            <Category data={prop} kkey={key} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
function FoodCardAlt(props) {
  let navigate = useNavigate();
  const gotoCart = () => {
    addToCart(props.data);
    navigate('/foods/cart/', { state: { data: props.data, type: 'quick' } });
  };
  const gotoPage = () => {
    navigate('/foods/page/', { state: { data: props.data } });
  };
  return (
    <>
      <div style={{ border: '1px solid #eaeaea', padding: 10, borderRadius: 20 }}>
        <div
          style={{
            padding: 8,
            backgroundColor: '#eaeaea',
            borderRadius: 5,
            margin: 0,
            marginBottom: 10
          }}
        >
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <span>Order Closes at {props.data.closeOrder}</span>
            </Grid>
            <Grid item>
              <span style={{ float: 'right' }}>Deliveries begin at {props.data.deliveryTime}</span>
            </Grid>
          </Grid>
        </div>
        {/* <Stack direction="row" alignItems="center"> */}
        <Grid container>
          <Grid item sm={12} md={4} lg={4} xs={12}>
            <Card
              style={{
                height: 150,
                width: '100%'
              }}
            >
              <div
                style={{
                  height: 150,
                  width: '100%',
                  backgroundImage: 'url(' + props.data.image[0].name + ')'
                }}
                className="img-bg"
              ></div>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12} md={8} lg={8}>
            <div style={{ padding: 10 }}>
              <Typography variant="h6">{props.data.name}</Typography>
              <Typography style={{ fontStyle: 'italic' }}>{props.data.restaurant.name}</Typography>
              <Rating value={props.data.rating} readOnly size="small" />
              <Typography variant="h6">{props.data.price} TZS</Typography>
              <Badge variant="standard" color="info" />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant="outlined" size="small" onClick={gotoPage}>
                    View
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" size="small" onClick={gotoCart}>
                    Place Order
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div></div>
        <div></div>
        {/* </Stack> */}
      </div>
    </>
  );
}
function CartItem(props) {
  return (
    <div style={{ padding: 10 }}>
      <Card elevation={4}>
        <Grid container padding={1} alignItems="center" justifyContent={'space-between'}>
          <Grid item xs={6} sm={8} md={8} lg={8} justifyContent="center">
            <Stack direction={'row'}>
              <Card>
                {/* <div style={{ backgroundImage: "url(/static/1.jpg)", width: 100, height: 100 }} className="img-bg"></div> */}
              </Card>
              <Typography variant="h6" padding={1}>
                {props.name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} alignItems="center" alignContent={'center'}>
            <Stack direction="column">
              <Stack direction={'row'} alignItems="center" justifyContent={'flex-end'}>
                <Typography align={'right'} variant="body">
                  {fCurrency(props.price)} TZS &nbsp;
                </Typography>
                <Fab variant="circular" onClick={props.decrementItem} size="small" color="primary">
                  <Icon icon={minimize} fontSize={20} color="#fff" />
                </Fab>
                <Typography variant={'body'} align="right" padding={3}>
                  x {props.quantity}
                </Typography>
                <Fab onClick={props.incrementItem} variant="circular" size="small" color="primary">
                  <Icon icon={plusFill} fontSize={20} color="#fff" />
                </Fab>
              </Stack>
              <Stack direction="column">
                <Typography align={'right'} variant="body">
                  Subtotal : <b>{fCurrency(props.total)} TZS</b>
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={4} md={4} lg={4}></Grid>
        </Grid>
      </Card>
    </div>
  );
}
function FoodPageDisplay(props) {
  let navigate = useNavigate();
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} lg={6} className="hgrid" sm={12}>
          <Card>
            <div
              style={{ width: '100%', height: 350, backgroundImage: 'url(' + props.image + ')' }}
              className="img-bg"
            ></div>
            {/* <img src={props.image} style={{ width: '100%' }} /> */}
          </Card>
        </Grid>
        <Grid item md={6} lg={6} sm={6} className="hgrid">
          <Typography variant="h5">{props.name}</Typography>
          <Typography variant="h6">
            {props.price} {props.currency}
          </Typography>
          <Typography variant="body">{props.restaurant}</Typography>
          <div>
            ({props.reviews}) <Rating value={props.rating} readOnly size="small" />
          </div>
          <FoodDescription body={props.details} />
          <br />
          <Stack direction="row" spacing={1}>
            <div style={{}}>
              <Fab>
                <Icon icon={heartO} fontSize={24} color={'#ff0000'} />
              </Fab>
            </div>
            <Button
              color={'secondary'}
              variant="contained"
              onClick={() => {
                addToCart(props.data);
              }}
            >
              Add to cart
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                addToCart(props.data);
                navigate('/foods/cart/');
              }}
            >
              Buy now
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
function RestaurantPageDisplay(props) {
  return (
    <div style={{ borderRadius: 0 }}>
      <Grid container padding={10} paddingTop={2} paddingBottom={2} spacing={3} alignItems="center">
        <Grid item md={1} xs={1} lg={1} className="hgrid" sm={1}>
          <img src="" />
        </Grid>
        <Grid item md={6} lg={6} xs={10} sm={6} className="hgrid">
          <Typography variant="h5">{props.name}</Typography>
          <Typography variant="h6">Open from Monday to Friday</Typography>
        </Grid>
      </Grid>
      <MessageTexting />
    </div>
  );
}
function MessageTexting(props) {
  const [show, setShow] = useState(false);
  var chatboxHeight = 0;
  if (window.outerHeight < 600) {
    chatboxHeight = window.outerHeight - 20;
  } else {
    chatboxHeight = window.outerHeight - window.outerHeight * 0.1;
  }
  const ContainerChat = (prop) => {
    return (
      <div className="chatContainer">
        <Card>
          <Stack direction="column">
            <div>
              <span
                onClick={() => {
                  setShow(false);
                }}
                style={{ float: 'right', padding: 19, position: 'relative' }}
              >
                <Icon icon={minimize} />
              </span>
              <Typography align="center" padding={2} variant="h6">
                Chat with Kulan Restaurant
              </Typography>
            </div>
            <div className="chatBox scrollbar">
              <div style={{ float: 'right', padding: 10 }}>
                <div
                  style={{
                    padding: 10,
                    backgroundColor: '#ff6f00',
                    color: '#fff',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomLeftRadius: 20,
                    maxWidth: 300
                  }}
                >
                  <Typography variant="body">
                    Hello HelloHello Hello Hello Hello Hello Hello Hello HelloHelloHello Hello Hello
                    Hello{' '}
                  </Typography>
                </div>
              </div>
              <div style={{ float: 'left', padding: 10 }}>
                <div
                  style={{
                    padding: 10,
                    backgroundColor: '#999',
                    color: '#fff',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    maxWidth: 300
                  }}
                >
                  <Typography variant="body">
                    Hello HelloHello Hello Hello Hello Hello Hello Hello HelloHelloHello Hello Hello
                    Hello{' '}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <Stack direction="row" spacing={1} padding={1}>
                <div style={{ width: '96%' }}>
                  <TextField fullWidth type={'text'} />
                  {/* <div style={{ border: '1px solid #ff6f00', borderRadius: 10, padding: 10 }}>
                    <input className="hot-input" style={{ width: '100%' }} />
                  </div> */}
                </div>
                <div>
                  <Fab size="small" color="primary">
                    <Icon icon={SendIcon} color="#fff" fontSize={20} />
                  </Fab>
                </div>
              </Stack>
            </div>
          </Stack>
        </Card>
      </div>
    );
  };
  return (
    <div>
      <div className="chatContainer">
        {show ? (
          <div className="animated slideIn">
            <ContainerChat />
          </div>
        ) : (
          false
        )}
        <Fab
          style={{ zIndex: 2 }}
          color="primary"
          onClick={() => {
            setShow(true);
          }}
        >
          <Icon fontSize={30} icon={message} />
        </Fab>
      </div>
    </div>
  );
}
function FoodDescription(prop) {
  return (
    <div style={{ paddingTop: 10 }}>
      <Typography variant="h5">Description</Typography>
      <div style={{ paddingTop: 20 }}>
        <Typography variant="body">{prop.body}</Typography>
      </div>
    </div>
  );
}
function FoodReviews(props) {
  return (
    <div style={{ paddingTop: 26 }}>
      <Typography style={{ paddingBottom: 10 }} variant="h5">
        Reviews
      </Typography>
      <Grid container spacing={3}>
        {props.data.map((prop) => (
          <Grid item lg={6} md={6} sm={12}>
            <Review title={prop.name} body={prop.cmomm} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
function Review() {
  return (
    <Card style={{}}>
      <Stack direction="row" alignItems={'center'} backgroundColor="#ededed">
        <div
          style={{
            backgroundImage: 'url(/static/3.png)',
            width: 60,
            height: 60,
            borderRadius: 30,
            margin: 10
          }}
          className="img-bg"
        ></div>
        <div>
          <Typography variant="h6">@Username</Typography>
          <Rating value={4} readOnly size="small" />
        </div>
      </Stack>
      <div style={{ padding: 10 }}>
        <Typography variant="body">
          asdjhasdhgashdga a dhadg akdga sdghas dahkgdk agygygfawhgruwqru qgriuoyjdn ue uhjdsh
          jdfhjados wgfdhaklhuia.
        </Typography>
      </div>
    </Card>
  );
}
function SearchApartments() {
  return (
    <Stack style={{ margin: 10 }}>
      <Card style={{ width: '100%' }}>
        <Grid container>
          <Grid padding={2} item md={12} lg={12} sm={12}>
            <SearchPlace />
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
}
function ApartmentLanding() {
  return (
    <div style={{ backgroundImage: 'url(/static/places.jp)', backgroundColor: '#dedede' }}>
      <div className="mb-4 mt-4" style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Grid container>
          <Grid item sm={1} lg={1} md={1}></Grid>
          <Grid item sm={12} xs={12} lg={4} md={4}>
            <SearchApartments />
          </Grid>
          <Grid item sm={1} lg={1} md={1}></Grid>
          <Grid item sm={12} xs={12} lg={5} md={5}>
            <Typography variant="h1" padding={1} color="#000">
              {' '}
              Enjoy the experience of quality Apartment services around the world.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
function ApartmentProvisions() {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item>
          <Provisions />
        </Grid>
        <Grid item>
          <Provisions />
        </Grid>
        <Grid item>
          <Provisions />
        </Grid>
        <Grid item>
          <Provisions />
        </Grid>
        <Grid item>
          <Provisions />
        </Grid>
      </Grid>
    </div>
  );
}
function Provisions() {
  return (
    <div className="">
      <Chip
        avatar={'/static/1.jpg'}
        label={'Free Wifi'}
        icon={<Icon icon={wifi} fontSize={20} />}
      />
    </div>
  );
}
function isLoggedIn() {
  let s = sessionStorage.getItem('userId');
  if (s != undefined) {
  }
  return false;
}
export {
  FoodCard,
  Provisions,
  ApartmentProvisions,
  RestaurantCard,
  HouseCard,
  HFooter,
  CategoriesH,
  CartItem,
  HFileReader,
  HLoader,
  RestaurantCardAlt,
  GroceryCard,
  FoodCardAlt,
  UserSearchbar,
  FoodPageDisplay,
  FoodDescription,
  FoodReviews,
  RestaurantPageDisplay,
  SearchApartments,
  ApartmentLanding,
  SlideShow,
  getCartSize,
  isLoggedIn,
  isMobile,
  addToFavourites
};
