import { Icon } from '@iconify/react';
import { Button, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import close from '@iconify/icons-eva/close-circle-fill';
import { useLocation, useNavigate } from 'react-router';
import API from 'src/modules/apiClient';
import Auth from 'src/modules/authentication';
class VerifyMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      email: props.email,
      error: false,
      error_message: ''
    };
  }
  componentDidMount() {}
  verifyAccount() {
    Auth.verifyEmail(this.state.email, this.state.code).then((r) => {
      var data = r.data;
      if (data.callback == 'success') {
        this.props.navigation('/chooseUse');
      } else {
        this.setState({ error_message: data.return_message, error: true });
      }
    });
  }
  render() {
    return (
      <div>
        <Stack
          direction={'column'}
          justifyContent="space-around"
          alignContent={'space-between'}
          style={{ height: window.innerHeight }}
        >
          <div></div>
          <Grid container>
            <Grid item sm={1} xs={1} md={4} lg={4}></Grid>
            <Grid item sm={10} xs={10} md={4} lg={4}>
              <Card>
                <div style={{ padding: 30 }}>
                  <Typography variant="body1" align="center">
                    A Verification mail was sent to your email. Please verify the Email address by
                    writing the Confirmation Code below to continue
                  </Typography>
                  {this.state.error ? (
                    <Stack
                      className="animated tada"
                      direction="row"
                      justifyContent={'center'}
                      marginBottom={1}
                      marginTop={1}
                    >
                      <Icon icon={close} style={{ marginTop: 1 }} color="red" />
                      <b>&nbsp;&nbsp;&nbsp;</b>
                      <Typography fontStyle={'bold'} align="center" color="red">
                        {this.state.error_message}
                      </Typography>
                    </Stack>
                  ) : null}
                  <br />
                  <Grid container>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        label="Confirmation Code"
                        fullWidth
                        onChange={(e) => {
                          this.setState({ code: e.target.value });
                        }}
                      />
                      <Button
                        onClick={() => {
                          this.verifyAccount();
                        }}
                        variant="contained"
                        fullWidth
                        size="large"
                        style={{ marginTop: 10 }}
                      >
                        <Typography variant="body1">Verify & continue</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item sm={1} xs={1} md={4} lg={4}></Grid>
          </Grid>
          <div></div>
        </Stack>
      </div>
    );
  }
}
export default function VerifyEmail() {
  let data = useLocation().state;
  let navigate = useNavigate();
  return <VerifyMail email={data.email} navigation={navigate} />;
}
