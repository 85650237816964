import { Icon } from '@iconify/react';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
// material
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { getCartSize } from 'src/components/hotpot';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

export default function CartWidget() {
  const [cart, setCart] = useState(getCartSize());
  setTimeout(() => {
    setCart(getCartSize());
  }, 100);
  return (
    <div>
      <RootStyle>
        <Link to="/foods/cart">
          {/* <Badge showZero badgeContent={cart} color="primary" max={99}> */}
          <Icon icon={shoppingCartFill} color="#ff6f00" width={24} height={24} />
          {/* </Badge> */}
        </Link>
      </RootStyle>
      <div></div>
    </div>
  );
}
