import API from './apiClient';

export default class orderAPI {
  static listOrders(data) {
    return API.ax.post(`orders/`, data).catch((e) => {
      console.log(e);
    });
  }
  static makeOrder(data) {
    return API.ax.post(`orders/`, data).catch((e) => {
      console.log(e);
    });
  }
}
