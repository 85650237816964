import { Grid, Card, Typography } from '@mui/material';
import * as React from 'react';
import { FoodDescription, FoodPageDisplay, FoodReviews } from 'src/components/hotpot';
import { ProductCartWidget } from 'src/components/_dashboard/products';
import { useNavigate, useLocation } from 'react-router-dom';

export default function FoodPage() {
  let data = useLocation().state.data;
  return (
    <div>
      <div
        style={{
          backgroundImage: 'url(' + data.image[0].name + ')',
          height: '100%',
          overflow: 'auto'
        }}
      >
        <div
          style={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255,255,255,.6)',
            padding: 7,
            height: 'max-content'
          }}
        >
          <Grid container>
            <Grid item sm={1} lg={1} md={1}></Grid>
            <Grid item sm={10} lg={10} md={10}>
              <FoodPageDisplay
                name={data.name}
                currency={data.currency}
                price={data.price}
                image={data.image[0].name}
                rating={data.rating}
                reviews={data.reviews}
                details={data.details}
                data={data}
              />
              <FoodReviews data={[3, 3, 3, 3, 3]} />
            </Grid>
          </Grid>
        </div>
      </div>
      <ProductCartWidget />
    </div>
  );
}
