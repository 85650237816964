import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { showHideLoader, setShowError } from 'src/pages/hotpot/components';
const AppContext = createContext(null);
const API_URL = 'https://outbox.spvafrica.com/api/';
const AuthProvider = ({ children }) => {
  const imgurl = 'https://outbox.spvafrica.com/';
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [logged, setLogin] = useState(false);
  const [age, setAge] = useState(null);
  const [cart, setCart] = useState([{ id: 1, name: 'Ugali Mshkaki', quantity: 1, price: 1000 }]);
  class api {
    static get(url, forcetoken) {
      let urlton = forcetoken ? forcetoken : token;
      let ax = axios.create({
        baseURL: `${API_URL}`,
        timeout: 180000000000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + urlton
        }
      });
      setToken(forcetoken);
      return ax.get(url).catch((e) => {
        setShowError(true, e);
      });
    }
    static post(url, data, forcetoken) {
      let urlton = forcetoken ? forcetoken : token;
      let ax = axios.create({
        baseURL: `${API_URL}`,
        timeout: 1800000000000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + urlton
        }
      });
      setToken(forcetoken);
      return ax.post(url, data).catch((e) => {
        setShowError(true, e);
      });
    }
  }
  let ax = axios.create({
    baseURL: `${API_URL}`,
    timeout: 180000000000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  });
  let up = axios.create({
    baseURL: `${API_URL}`,
    timeout: 180000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token
    }
  });

  const uploadFile = (endpoint, data) => {
    // showHideLoader(true);
    return up
      .post(endpoint, data)
      .catch((e) => {
        console.log('l');
        setShowError(true, e);
      })
      .finally(() => {
        // showHideLoader(false);
      });
  };
  let post = (endpoint, data) => {
    // showHideLoader(true);
    return ax
      .post(endpoint, data)
      .catch((e) => {
        // setShowError(true, e);
      })
      .finally(() => {
        // showHideLoader(false);
      });
  };
  let get = (endpoint) => {
    // showHideLoader(true);
    return ax
      .get(endpoint)
      .catch((e) => {
        // setShowError(true, e);
      })
      .finally(() => {
        // showHideLoader(false);
      });
  };
  const guest_token = () => {
    return ax
      .post('token', {
        username: 'guest.guest',
        password: 'password_quest',
        browserId: 'chrome'
      })
      .catch((e) => {
        setShowError(true, e);
      })
      .finally(() => {
        showHideLoader(false);
      });
  };
  const updateToken = (tokn) => {
    setToken(tokn);
  };
  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        guest_token,
        post,
        get,
        role,
        setRole,
        uploadFile,
        updateToken,
        api,
        age,
        setAge,
        setLogin,
        logged,
        cart,
        setCart,
        imgurl
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
const InitialUser = () => {
  const [sr, setSr] = useState(false);
  const { guest_token, setToken, setRole, token } = useAPI();
  // useEffect(() => {

  //     if (token == null) {
  //         guest_token().then((r) => {
  //             setToken(r.data.access)
  //             setSr(true)
  //             setRole('guest')
  //         })
  //     }
  // }, [])

  return <div></div>;
};
const useAPI = () => useContext(AppContext);
export { AppContext, useAPI, AuthProvider, InitialUser };
