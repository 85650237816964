import * as React from 'react';
import { FoodCard, RestaurantCard } from 'src/components/hotpot';
import Carousel from 'react-material-ui-carousel';
export default class HotSpecial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: props.data
    };
    console.log(this.state.restaurant);
  }
  render() {
    return (
      <div style={{ width: '100%', padding: 10 }}>
        <Carousel animation="slide" width={'100%'} height={400}>
          {this.props.data.map((prop, key) => (
            <div style={{}}>
              <RestaurantCard data={prop} />
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
